import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Paging from "../../adapters/paging-adapter";

export default {
  namespaced: true,
  state: {
    vacancies: [],
    paging: {},
  },

  mutations: {
    setVacancies(state, payload) {
      state.vacancies = payload;
    },
    setPaging(state, payload) {
      state.paging = payload;
    },
    removeVacancy(state, payload) {
      state.vacancies = state.vacancies.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getVacancies({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .get(`/vacancyApply${payload}`)
        .then((response) => {
          const res = response.data.data.vacancyApplies;
          const paging = response.data.data.paging;

          commit("setVacancies", res);
          commit("setPaging", new Paging(paging));

          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteVacancy({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/vacancyApply/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeVacancy", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
