import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Appeal from "../../adapters/appeal-adapter";
import Paging from "../../adapters/paging-adapter";

export default {
  namespaced: true,
  state: {
    appeals: [],
    paging: {},
  },

  mutations: {
    setAppeals(state, payload) {
      state.appeals = payload;
    },
    setPaging(state, payload) {
      state.paging = payload;
    },
    removeAppeal(state, payload) {
      state.appeals = state.appeals.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getAppeals({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .get(`/carier${payload}`)
        .then((response) => {
          const res = response.data.data.cariers;
          const paging = response.data.data.paging;
          let lang = store.state.lang;
          commit(
            "setAppeals",
            res.map((i) => {
              return new Appeal(i, lang);
            })
          );
          commit("setPaging", new Paging(paging));
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteAppeal({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/carier/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeAppeal", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
