import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Vacancy from "../../adapters/vacancy-adapter";

export default {
  namespaced: true,
  state: {
    vacancies: [],
  },

  mutations: {
    setVacancies(state, payload) {
      state.vacancies = payload;
    },
    removeVacancy(state, payload) {
      state.vacancies = state.vacancies.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getVacancies({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/vacancy`)
        .then((response) => {
          const res = response.data.data.vacancies;
          let lang = store.state.lang;
          commit(
            "setVacancies",
            res.map((i) => {
              return new Vacancy(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addVacancy(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/vacancy`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteVacancy({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/vacancy/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeVacancy", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/vacancy/${id}`)
        .then((response) => {
          const res = response.data.data.vacancy;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updateVacancy(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/vacancy/${payload.id}`, payload.formData)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
