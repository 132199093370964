export default class Partner {
  constructor(item, lang) {
    this._setId(item);
    this._setPhotoUrl(item);
    this._setName(item, lang);
    this._setUrl(item);
  }

  /**
   * Set Id
   * @param id
   * @private
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * Set photoUrl
   * @param photoUrl
   * @private
   */
  _setPhotoUrl({ photoUrl }) {
    this.photoUrl = photoUrl;
  }

  /**
   * Set url
   * @param url
   * @private
   */
  _setUrl({ url }) {
    this.url = url;
  }

  /**
   * Set name
   * @param name
   * @private
   */
  _setName(item, lang) {
    let key = "name_" + lang;
    this.name = item[key];
  }
}
