<template>
  <section class="home-slider">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Slider" link="slider-add" />
      </v-col>
      <v-col cols="12">
        <v-card v-if="sliders && sliders.length">
          <v-card v-if="sliders && sliders.length">
            <v-data-table
              :headers="headers"
              :items="sliders"
              disable-sort
              class="table"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                itemsPerPageText: $store.state.statics.DataPerPage,
              }"
            >
              <template v-slot:[`item.photoUrl`]="{ item }">
                <img :src="item.photoUrl" />
              </template>
              <template v-slot:[`item.title`]="{ item }">
                {{ getText(item.title) }}
                <!-- <span v-html="item.title"></span> -->
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  size="sm"
                  icon
                  @click="
                    $router.push({
                      name: 'slider-edit',
                      params: { id: item.id },
                    })
                  "
                >
                  <v-icon small>mdi-pen</v-icon>
                </v-btn>

                <v-btn @click="removeDialog(item.id)" size="sm" icon>
                  <v-icon small color="red"> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
        <div v-else>
          <empty-alert />
        </div>
      </v-col>
    </v-row>
    <delete-dialog v-model="dialog" @delete="removeItem" />
  </section>
</template>
<script>
import { mapState } from "vuex";
import remove from "@/mixins/remove.js";

export default {
  computed: {
    ...mapState({
      sliders: (state) => state.slider.sliders,
    }),
    headers() {
      return [
        {
          text: this.$store.state.statics.Photo,
          value: "photoUrl",
          cellClass: "slider-item__image",
        },
        { text: this.$store.state.statics.Title, value: "title" },
        {
          text: this.$store.state.statics.Edit,
          value: "actions",
          align: "end",
        },
      ];
    },
  },
  created() {
    this.$store.dispatch("slider/getSliders");
  },
  methods: {
    removeItem() {
      this.$store.dispatch("slider/deleteSlider", this.selected);
      this.clearDialog();
    },
    getText(str) {
      let regex = /(<([^>]+)>)/gi;
      return str.replace(regex, "");
    },
  },
  mixins: [remove],
};
</script>