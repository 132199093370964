import axios from "axios";
import store from "./../index";
import Vue from "vue";

export default {
  namespaced: true,
  actions: {
    getVideo() {
      store.commit("setLoader", true);
      return axios
        .get(`/video`)
        .then((response) => {
          const res = response.data.data.videos[0];
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updateVideo(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/video/${payload.id}`, payload.formData)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
