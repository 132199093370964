import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Position from "../../adapters/position-adapter";

export default {
  namespaced: true,
  state: {
    positions: [],
  },

  mutations: {
    setPositions(state, payload) {
      state.positions = payload;
    },
    removePosition(state, payload) {
      state.positions = state.positions.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getPositions({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/position`)
        .then((response) => {
          const res = response.data.data.positions;
          let lang = store.state.lang;

          commit(
            "setPositions",
            res.map((i) => {
              return new Position(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addPosition(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/position`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deletePosition({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/position/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removePosition", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/position/${id}`)
        .then((response) => {
          const res = response.data.data.position;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updatePosition(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/position/${payload.id}`, payload)
        .then((response) => {
          const mes = response.data.message;
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
