<template>
  <tr class="slider-item pointer no-select" @dblclick="$emit('dialog', index)">
    <td>
      {{ item.name }}
      {{item.surname}}
    </td>
    <td>
      {{ item.phone }}
    </td>
    <td>
      {{date}}
    </td>
    <td class="table__actions">
      <v-btn size="sm" icon @click="removeItem">
        <v-icon small color="red">mdi-trash-can</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
  <script>
import {utcToLocale} from "../../functions/utc-to-locale";

export default {
  name: "SliderItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    removeItem() {
      this.$emit("removeItem", this.item.id);
    },
  },
  computed: {
    date() {
      let date = this.item.updatedDate;
      return utcToLocale(date);
    }
  }
};
</script>
