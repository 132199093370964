<template>
  <div class="filters d-flex align-center">
    <div class="filters__input mr-3" style="width: 100%">
      <v-row align="center">
        <v-col cols="3">
          <v-text-field
            :label="$store.state.statics.Name"
            v-model="api.Name"
            dense
            outlined
            hide-details
            background-color="inputs"
            @keypress.enter="search"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :label="$store.state.statics.Surname"
            v-model="api.Surname"
            dense
            outlined
            hide-details
            background-color="inputs"
            @keypress.enter="search"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="professions"
            item-text="name"
            item-value="id"
            :label="$store.state.statics.Profession"
            dense
            v-model="api.ProfessionId"
            outlined
            background-color="inputs"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="professions"
            item-text="name"
            item-value="id"
            :label="$store.state.statics.SpecialtyCertification"
            dense
            v-model="api.JobSpecialtyId"
            outlined
            background-color="inputs"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <div class="filters__button d-flex align-center">
      <v-btn
        x-small
        color="secondary"
        class="mr-5"
        fab
        v-show="this.query.length"
        @click="refresh"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn small color="primary" class="mr-1" fab @click="search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn small color="secondary" fab @click="dialog = true">
        <v-icon>mdi-magnify-scan</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="dialog" width="1000" top scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $store.state.statics.DetailedSearch }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <!-- name -->
            <v-col cols="4">
              <v-text-field
                :label="$store.state.statics.Name"
                v-model="api.Name"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>
            <!-- surname -->
            <v-col cols="4">
              <v-text-field
                :label="$store.state.statics.Surname"
                v-model="api.Surname"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>
            <!-- patronymic -->
            <v-col cols="4">
              <v-text-field
                :label="$store.state.statics.Patronymic"
                v-model="api.Patronymic"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>
            <!-- district -->
            <v-col cols="4">
              <v-text-field
                :label="$store.state.statics.Residence"
                v-model="api.District"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>
            <!-- birthdate  -->
            <v-col cols="4">
              <v-menu
                v-model="dtp1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="api.BirthDate"
                    clearable
                    :label="$store.state.statics.BirthDate"
                    readonly
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="api.BirthDate = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="api.BirthDate"
                  @change="dtp1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- phone -->
            <v-col cols="4">
              <v-text-field
                :label="$store.state.statics.Phone"
                v-model="api.Phone"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>

            <!-- Email -->
            <v-col cols="4">
              <v-text-field
                :label="$store.state.statics.Email"
                v-model="api.Email"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>

            <!-- IsMarried -->
            <v-col cols="4">
              <v-select
                :items="isMarried"
                item-text="name"
                item-value="id"
                :label="$store.state.statics.FamilyStatus"
                dense
                v-model="api.IsMarried"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col>

            <!-- IsHaveChild -->
            <v-col cols="4">
              <v-select
                :items="isHaveChild"
                item-text="name"
                item-value="id"
                :label="$store.state.statics.DoYouHaveAChilderen"
                dense
                v-model="api.IsHaveChild"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <v-divider class="my-4" />

          <v-row>
            <!-- InstitutionId -->
            <v-col cols="6">
              <v-select
                :items="institutions"
                item-text="name"
                item-value="id"
                :label="$store.state.statics.HighInstitution"
                dense
                v-model="api.InstitutionId"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col>

            <!-- ProfessionId -->
            <v-col cols="6">
              <v-select
                :items="professions"
                item-text="name"
                item-value="id"
                :label="$store.state.statics.ProfessionOfEducation"
                dense
                v-model="api.ProfessionId"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <div class="label">
                {{ $store.state.statics.EducationYear }}
              </div>
            </v-col>

            <!-- StartDateHigherEducation  -->
            <v-col cols="6" class="pt-1">
              <v-menu
                v-model="dtp2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="api.StartDateHigherEducation"
                    clearable
                    :label="$store.state.statics.StartDate"
                    readonly
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="api.StartDateHigherEducation = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="api.StartDateHigherEducation"
                  @change="dtp2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- EndDateHigherEducation  -->
            <v-col cols="6" class="pt-1">
              <v-menu
                v-model="dtp3"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="api.EndDateHigherEducation"
                    clearable
                    :label="$store.state.statics.EndDate"
                    readonly
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="api.EndDateHigherEducation = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="api.EndDateHigherEducation"
                  @change="dtp3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
<!-- 
            Language
            TODO: dil hissesi deyishecek
            <v-col cols="6">
              <v-text-field
                :label="$store.state.statics.Language"
                v-model="api.Language"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>

            Level
            TODO: dil seviyyesi deyishecek
            <v-col cols="6">
              <v-select
                :items="districts"
                item-text="name"
                item-value="id"
                :label="$store.state.statics.Level"
                dense
                v-model="api.Level"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col> -->
          </v-row>

          <v-divider class="my-4" />
          <v-row>
            <!-- IsMinistryofPublicHealthCertificated -->
            <v-col cols="12">
              <v-select
                :items="isMinistryofPublicHealthCertificated"
                item-text="name"
                item-value="id"
                :label="
                  $store.state.statics.IsMinistryofPublicHealthCertificated
                "
                dense
                v-model="api.IsMinistryofPublicHealthCertificated"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col>

            <!-- JobSpecialtyId -->
            <v-col cols="6" v-show="api.IsMinistryofPublicHealthCertificated">
              <v-select
                :items="professions"
                item-text="name"
                item-value="id"
                :label="$store.state.statics.SpecialtyCertification"
                dense
                v-model="api.JobSpecialtyId"
                outlined
                background-color="inputs"
                hide-details
              ></v-select>
            </v-col>
            <!-- OrganisationName -->
            <v-col :cols="hasCertificateCol">
              <v-text-field
                :label="$store.state.statics.OrganisationName"
                v-model="api.OrganisationName"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>

            <!-- Position -->
            <v-col cols="6">
              <v-text-field
                :label="$store.state.statics.Position"
                v-model="api.Position"
                dense
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>

            <!-- Experience -->
            <v-col cols="6">
              <v-text-field
                :label="$store.state.statics.WorkExperienceDate"
                v-model="api.Experience"
                dense
                type="number"
                outlined
                hide-details
                background-color="inputs"
                @keypress.enter="search"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" class="pb-0">
              <div class="label">
                {{ $store.state.statics.WorkGraphic }}
              </div>
            </v-col> -->

            <!-- StartTimeLastWork 
            <v-col cols="6" class="pt-1">
              <v-menu
                v-model="dtp6"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="api.StartTimeLastWork"
                    clearable
                    :label="$store.state.statics.StartDate"
                    readonly
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="api.StartTimeLastWork = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="api.StartTimeLastWork"
                  @change="dtp6 = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

            EndTimeLastWork 
            <v-col cols="6" class="pt-1">
              <v-menu
                v-model="dtp7"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="api.EndTimeLastWork"
                    clearable
                    :label="$store.state.statics.EndDate"
                    readonly
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="api.EndTimeLastWork = null"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="api.EndTimeLastWork"
                  @change="dtp7 = false"
                ></v-time-picker>
              </v-menu>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ $store.state.statics.Close }}
          </v-btn>
          <v-btn color="primary" @click="search">
            {{ $store.state.statics.Search }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      api: {},
      query: "",
      dtp1: false,
      dtp2: false,
      dtp3: false,
      dtp4: false,
      dtp5: false,
      dtp6: false,
      dtp7: false,
      dialog: false,
    };
  },
  methods: {
    search() {
      this.query = "";
      this.dialog = false;
      //   TODO: iş saatlarını reqemle göndermek
      
      let start = this.api.StartTimeLastWork;
      let end = this.api.EndTimeLastWork;
      if (start) {
        let startSplit = start.split(":");
        let s1 = Number(startSplit[0]) * 60 + Number(startSplit[1]);
        this.api.StartTimeLastWork = s1;
      }
      if (end) {
        let endSplit = end.split(":");
        let e1 = Number(endSplit[0]) * 60 + Number(endSplit[1]);
        this.api.StartTimeLastWork = e1;
      }

      for (const [key, value] of Object.entries(this.api)) {
        if (value && value != "" && value != null) {
          this.query += "&" + key + "=" + value;
        }
      }
      this.$emit("search", this.query);
    },
    refresh() {
      this.api = {};
      this.query = "";
      this.$emit("search", this.query);
    },
  },
  mounted() {
    if (!this.positions.length) {
      this.$store.dispatch("positions/getPositions");
    }
    if (!this.professions.length) {
      this.$store.dispatch("profession/getProfessions");
    }
    if (!this.institutions.length) {
      this.$store.dispatch("institution/getInstitutions");
    }
  },
  computed: {
    ...mapState({
      positions: (state) => state.positions.positions,
      professions: (state) => state.profession.professions,
      institutions: (state) => state.institution.institutions,
    }),
    isMarried() {
      return [
        { name: this.$store.state.statics.Married, id: true },
        { name: this.$store.state.statics.UnMarried, id: false },
      ];
    },
    isHaveChild() {
      return [
        { name: this.$store.state.statics.Yes, id: true },
        { name: this.$store.state.statics.No, id: false },
      ];
    },
    isMinistryofPublicHealthCertificated() {
      return [
        { name: this.$store.state.statics.Have, id: true },
        { name: this.$store.state.statics.Havenot, id: false },
      ];
    },

    hasCertificateCol() {
      return this.api.IsMinistryofPublicHealthCertificated ? 6 : 12;
    },
  },
};
</script>