<template>
  <div class="text-center">
    <div class="image-container">
      <video :src="newImage" v-if="newImage"></video>
      <video :src="image" v-else-if="image"></video>
    </div>
    <input type="file" ref="photoInput" hidden @change="changePhoto" />
    <v-btn color="primary" small @click.prevent="clickInput">
      {{ $store.state.statics.ChooseTheVideo }}</v-btn
    >
  </div>
</template>
<script>
export default {
  props: {
    image: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      newImage: null,
    };
  },
  methods: {
    clickInput() {
      this.$refs.photoInput.click();
    },
    changePhoto(e) {
      var file = e.target.files[0];
      if (file) {
        this.newImage = URL.createObjectURL(file);
      }
      this.$emit("new-video", file);
    },
  },
};
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image-container {
  width: 150px;
  height: 100px;
  margin: 0 auto;
  border: 1px solid #dedede;
  margin-bottom: 10px;
}
</style>