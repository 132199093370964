const utcToLocale = (str) => {
    let date = new Date(str);
    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    newDate.setHours(hours - offset);

    let fullDate = newDate.toLocaleString();

    let dateArr = fullDate.split(',')[0].split(/[\s/.]+/);
    if(dateArr[0].length===1) {
        dateArr[0]='0'+dateArr[0];
    }
    if(dateArr[1].length===1) {
        dateArr[1]='0'+dateArr[1];
    }

    return dateArr[0]+'.'+dateArr[1]+'.'+dateArr[2];
}

module.exports = {
    utcToLocale,
};
