export default class Slider {
  constructor(item, lang) {
    this._setId(item);
    this._setPhotoUrl(item);
    this._setTitle(item, lang);
  }

  /**
   * Set Id
   * @param id
   * @private
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * Set title
   * @param title
   * @private
   */
  _setTitle(item, lang) {
    let key = "title_" + lang;
    this.title = item[key];
  }

  /**
   * Set photo
   * @param photo
   * @private
   */
  _setPhotoUrl({ photoUrl }) {
    this.photoUrl = photoUrl;
  }
}
