<template>
  <section class="partner-add">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.AddPartner" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mb-5">
                <language @lang="langChanged($event)" />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$store.state.statics.Link"
                  v-model="partner.url"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-row v-show="lang === '_az'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('az')"
                      v-model="partner.name_az"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ description("az") }} </label>
                    <vue-editor v-model="partner.about_az"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_de'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('de')"
                      v-model="partner.name_de"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ description("de") }} </label>
                    <vue-editor v-model="partner.about_de"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_en'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('en')"
                      v-model="partner.name_en"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ description("en") }} </label>
                    <vue-editor v-model="partner.about_en"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_ru'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('ru')"
                      v-model="partner.name_ru"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ description("ru") }} </label>
                    <vue-editor v-model="partner.about_ru"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_tr'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('tr')"
                      v-model="partner.name_tr"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ description("tr") }} </label>
                    <vue-editor v-model="partner.about_tr"></vue-editor>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <image-input-add
                  :refresh="resfresh"
                  @new-image="changePhoto($event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'partners' })"
                >
                  {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="addPartner">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
import {objectToFormData} from '@/functions/to-form-data';
export default {
  data() {
    return {
      partner: {},
      resfresh: true,
    };
  },
  methods: {
    name(e) {
      return this.$store.state.statics.Name + " (" + e + ")";
    },
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    addPartner() {
      var l = this.partner;

      this.$store.dispatch("partners/addPartner", objectToFormData(l)).then((res) => {
        if (res) {
          this.resfresh = true;
          this.partner = {};
        }
      });
    },
    changePhoto(e) {
      this.resfresh = false;
      this.partner.photo = e;
    },
  },
  mixins: [lang],
};
</script>