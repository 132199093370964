export default class Paging {
  constructor(item) {
    this._setTotalItems(item);
    this._setTotalPages(item);
    this._setCurrent(item);
    this._setSize(item);
  }

  /**
   * set totalItems
   * @param {totalItems} param0
   */
  _setTotalItems({ totalItems }) {
    this.totalItems = totalItems;
  }

  /**
   * set totalPages
   * @param {totalPages} param0
   */
  _setTotalPages({ totalPages }) {
    this.totalPages = totalPages;
  }

  /**
   * set current
   * @param {currentPage} param0
   */
  _setCurrent({ currentPage }) {
    this.current = currentPage;
  }

  /**
   * set size
   * @param {pageSize} param0
   */
  _setSize({ pageSize }) {
    this.size = pageSize;
  }
}
