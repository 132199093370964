<template>
  <div class="contact-page">
    <Contacts />
  </div>
</template>
<script>
import Contacts from "@/components/contact/Contacts.vue";
export default {
  name: "ContactPage",
  components: {
    Contacts,
  },
};
</script>