import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// Modules
import auth from "./modules/auth";
import services from "./modules/services";
import contact from "./modules/contact";
import lead from "./modules/lead";
import faq from "./modules/faq";
import career from "./modules/career";
import partners from "./modules/partners";
import offer from "./modules/offer";
import feedbacks from "./modules/feedbacks";
import slider from "./modules/slider";
import video from "./modules/video";
import positions from "./modules/positions";
import team from "./modules/team";
import questions from "./modules/questions";
import vacancies from "./modules/vacancies";
import work from "./modules/work";
import institution from "./modules/institution";
import profession from "./modules/profession";
import nurses from "./modules/nurses";
import getStaff from "./modules/get-staff";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: false,
    token: null,
    statics: {},
    lang: "en",
  },
  getters: {
    getLoader(state) {
      return state.loader;
    },
    getToken(state) {
      return state.token;
    },
    getEmail() {
      let e = localStorage.getItem("admin.igaplus.login");
      if (e && e.length) {
        return e;
      }
      return false;
    },
  },
  mutations: {
    setLoader(state, payload) {
      state.loader = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    removeToken(state) {
      state.token = null;
      localStorage.removeItem("admin.igaplus.login");
      localStorage.removeItem("admin.igaplus");
    },
    setStatisc(state, payload) {
      let obj = {};
      for (const i of payload) {
        obj[i.key] = i.value;
      }
      state.statics = obj;
    },
    setLang(state, payload) {
      localStorage.setItem("igaplus-admin-language", payload);
      state.lang = payload;
    },
    setLangManual(state, payload) {
      localStorage.setItem("igaplus-admin-language", payload);
      state.lang = payload;
      location.reload();
    },
  },
  actions: {
    getStatics({ commit }) {
      commit("setLoader", true);
      return axios
        .get(`/translation/getadmin`)
        .then((response) => {
          const res = response.data.translations;
          commit("setStatisc", res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getLanguage({ commit }) {
      let l = localStorage.getItem("igaplus-admin-language");
      if (l) {
        commit("setLang", l);
      } else {
        commit("setLang", "az");
      }
      return true;
    },
  },
  modules: {
    auth,
    services,
    contact,
    lead,
    faq,
    career,
    partners,
    offer,
    feedbacks,
    slider,
    video,
    positions,
    team,
    questions,
    vacancies,
    work,
    institution,
    profession,
    getStaff,
    nurses,
  },
});
