export default {
  data() {
    return {
      lang: "_az",
    };
  },
  methods: {
    langChanged(e) {
      this.lang = e;
    },
  },
};
