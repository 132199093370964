<template>
  <div class="about-page">
    <Positions />
    <Team />
  </div>
</template>
<script>
import Positions from "@/components/about/Positions.vue";
import Team from "@/components/about/Team.vue";
export default {
  name: "AboutPage",
  components: {
    Positions,
    Team,
  },
  created() {
    this.$store.dispatch("positions/getPositions");
  },
};
</script>