import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Paging from "../../adapters/paging-adapter";

export default {
  namespaced: true,
  state: {
    questions: [],
    paging: {},
  },

  mutations: {
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setPaging(state, payload) {
      state.paging = payload;
    },
    removeQuestion(state, payload) {
      state.questions = state.questions.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getQuestions({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .get(`/contactForm${payload}`)
        .then((response) => {
          const res = response.data.data.contactForms;
          const paging = response.data.data.paging;

          commit("setQuestions", res);
          commit("setPaging", new Paging(paging));

          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteQuestion({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/contactForm/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeQuestion", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
