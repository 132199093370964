<template>
  <div class="text-center">
    <div class="image-container">
      <img :src="image" v-if="image" />
    </div>
    <input type="file" ref="photoInput" hidden @change="changePhoto" />
    <v-btn color="primary" small @click.prevent="clickInput">
      {{ $store.state.statics.ChooseThePhoto }}
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    refresh: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    clickInput() {
      this.$refs.photoInput.click();
    },
    changePhoto(e) {
      var file = e.target.files[0];
      if (file) {
        this.image = URL.createObjectURL(file);
      }
      this.$emit("new-image", file);
    },
  },
  watch: {
    refresh(val) {
      if (val === true) {
        this.image = null;
        this.$refs.photoInput.value = "";
      }
    },
  },
};
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.image-container {
  width: 150px;
  height: 100px;
  margin: 0 auto;
  border: 1px solid #dedede;
  margin-bottom: 10px;
}
</style>