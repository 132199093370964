<template>
  <section class="slider-add">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.AddSlider" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mb-5">
                <language @lang="langChanged($event)" />
              </v-col>

              <v-col cols="12">
                <v-switch
                  v-model="slider.isActive"
                  :label="$store.state.statics.Activity"
                  style="width: fit-content"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-row v-show="lang === '_az'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.url_az"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("az") }} </label>
                    <vue-editor v-model="slider.title_az"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('az')"
                      v-model="slider.description_az"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_de'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.url_de"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("de") }} </label>
                    <vue-editor v-model="slider.title_de"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('de')"
                      v-model="slider.description_de"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_en'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.url_en"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("en") }} </label>
                    <vue-editor v-model="slider.title_en"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('en')"
                      v-model="slider.description_en"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_ru'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.url_ru"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("ru") }} </label>
                    <vue-editor v-model="slider.title_ru"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('ru')"
                      v-model="slider.description_ru"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_tr'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.url_tr"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("tr") }} </label>
                    <vue-editor v-model="slider.title_tr"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('tr')"
                      v-model="slider.description_tr"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <image-input-add
                  :refresh="resfresh"
                  @new-image="changePhoto($event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'home' })"
                >
                  {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="addSlider">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
import {objectToFormData} from '@/functions/to-form-data';
export default {
  data() {
    return {
      slider: {
        isActive: true,
      },
      resfresh: true,
    };
  },
  methods: {
    name(e) {
      return this.$store.state.statics.Title + " (" + e + ")";
    },
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    addSlider() {
      let l = this.slider;

      // let formData = new FormData();
      // formData.append("Title_az", l.title_az);
      // formData.append("Title_de", l.title_de);
      // formData.append("Title_en", l.title_en);
      // formData.append("Title_ru", l.title_ru);
      // formData.append("Description_az", l.description_az);
      // formData.append("Description_de", l.description_de);
      // formData.append("Description_en", l.description_en);
      // formData.append("Description_ru", l.description_ru);
      // formData.append("URL_az", l.url_az);
      // formData.append("URL_de", l.url_de);
      // formData.append("URL_en", l.url_en);
      // formData.append("URL_ru", l.url_ru);
      // formData.append("IsActive", l.isActive);
      //
      //
      // if (l.photo) {
      //   formData.append("Photo", l.photo);
      // }

      this.$store.dispatch("slider/addSlider", objectToFormData(l)).then((res) => {
        if (res) {
          this.resfresh = true;
          this.slider = { isActive: true };
        }
      });
    },
    changePhoto(e) {
      this.resfresh = false;
      this.slider.photo = e;
    },
  },
  mixins: [lang],
};
</script>