import Home from "../views/home";

export default [
  {
    path: "/home",
    meta: { requiresLogin: true, name: "HomePage" },
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    meta: { requiresLogin: true, name: "WhoAreWe" },
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/about"),
  },
  {
    path: "/services",
    meta: {
      requiresLogin: true,
      name: "About",
    },
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/services"),
  },
  {
    path: "/partners",
    meta: { requiresLogin: true, name: "Partners" },
    name: "partners",
    component: () =>
      import(/* webpackChunkName: "partners" */ "../views/partners"),
  },
  {
    path: "/career",
    meta: { requiresLogin: true, name: "Carier" },
    name: "career",
    component: () => import(/* webpackChunkName: "career" */ "../views/career"),
  },
  {
    path: "/contact",
    meta: { requiresLogin: true, name: "Contact" },
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact"),
  },

  {
    path: "/faq",
    meta: { requiresLogin: true, name: "Benefit" },
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/faq"),
  },
  {
    path: "/lead",
    meta: { requiresLogin: true, name: "PageDescription" },
    name: "lead",
    component: () => import(/* webpackChunkName: "lead" */ "../views/lead"),
  },
  {
    path: "/questions",
    meta: {requiresLogin: true, name: "Questions"},
    name: "questions",
    component: () =>
        import(/* webpackChunkName: "questions" */ "../views/questions"),
  },
  {
    path: "/nurses",
    meta: {requiresLogin: true, name: "Nurses"},
    name: "nurses",
    component: () =>
        import(/* webpackChunkName: "nurses" */ "../views/nurses"),
  },
  {
    path: "/work",
    meta: {requiresLogin: true, name: "VacancyApply"},
    name: "work",
    component: () => import(/* webpackChunkName: "work" */ "../views/work"),
  },
  {
    path: "/get-staff",
    meta: {requiresLogin: true, name: "ClinicAppeals"},
    name: "get-staff",
    component: () =>
      import(/* webpackChunkName: "get-staff" */ "../views/get-staff"),
  },
  {
    path: "/vacancies",
    meta: { requiresLogin: true, name: "Vacancies" },
    name: "vacancies",
    component: () =>
      import(/* webpackChunkName: "vacancies" */ "../views/vacancies"),
  },
  {
    path: "/settings",
    meta: { requiresLogin: true, name: "Settings" },
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/settings"),
  },

  {
    path: "/login",
    meta: { requiresLogin: true, name: "" },
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login"),
  },

  //create
  {
    path: "/home/slider-add",
    name: "slider-add",
    component: () =>
      import(/* webpackChunkName: "slider-add" */ "../views/home/slider-add"),
    meta: { requiresLogin: true, name: "HomePage" },
  },
  {
    path: "/home/feedback-add",
    name: "feedback-add",
    component: () =>
      import(
        /* webpackChunkName: "feedback-add" */ "../views/home/feedback-add"
      ),
    meta: { requiresLogin: true, name: "HomePage" },
  },
  {
    path: "/about/team-add",
    name: "team-add",
    component: () =>
      import(/* webpackChunkName: "team-add" */ "../views/about/team-add"),
    meta: { requiresLogin: true, name: "WhoAreWe" },
  },
  {
    path: "/partners/partner-add",
    name: "partner-add",
    component: () =>
      import(
        /* webpackChunkName: "partner-add" */ "../views/partners/partner-add"
      ),
    meta: { requiresLogin: true, name: "Partner" },
  },
  {
    path: "/career/vacancy-add",
    name: "vacancy-add",
    component: () =>
      import(
        /* webpackChunkName: "vacancy-add" */ "../views/career/vacancy-add"
      ),
    meta: { requiresLogin: true, name: "Carier" },
  },
  {
    path: "/faq/faq-add",
    name: "faq-add",
    component: () =>
      import(/* webpackChunkName: "faq-add" */ "../views/faq/faq-add"),
    meta: { requiresLogin: true, name: "Benefit" },
  },
  {
    path: "/about/position-add",
    name: "position-add",
    component: () =>
      import(
        /* webpackChunkName: "position-add" */ "../views/about/position-add"
      ),
    meta: { requiresLogin: true, name: "WhoAreWe" },
  },

  {
    path: "/career/profession-add",
    name: "profession-add",
    component: () =>
      import(
        /* webpackChunkName: "profession-add" */ "../views/career/profession-add"
      ),
    meta: { requiresLogin: true, name: "Carier" },
  },
  {
    path: "/career/institution-add",
    name: "institution-add",
    component: () =>
      import(
        /* webpackChunkName: "institution-add" */ "../views/career/institution-add"
      ),
    meta: { requiresLogin: true, name: "Carier" },
  },

  //edit
  {
    path: "/home/slider-edit/:id",
    name: "slider-edit",
    component: () =>
      import(/* webpackChunkName: "slider-edit" */ "../views/home/slider-edit"),
    meta: { requiresLogin: true, name: "HomePage" },
  },
  {
    path: "/home/feedback-edit/:id",
    name: "feedback-edit",
    component: () =>
      import(
        /* webpackChunkName: "feedback-edit" */ "../views/home/feedback-edit"
      ),
    meta: { requiresLogin: true, name: "HomePage" },
  },
  {
    path: "/about/team-edit/:id",
    name: "team-edit",
    component: () =>
      import(/* webpackChunkName: "team-edit" */ "../views/about/team-edit"),
    meta: { requiresLogin: true, name: "WhoAreWe" },
  },
  {
    path: "/partners/partner-edit/:id",
    name: "partner-edit",
    component: () =>
      import(
        /* webpackChunkName: "partner-edit" */ "../views/partners/partner-edit"
      ),
    meta: { requiresLogin: true, name: "Partner" },
  },
  {
    path: "/career/vacancy-edit/:id",
    name: "vacancy-edit",
    component: () =>
      import(
        /* webpackChunkName: "vacancy-edit" */ "../views/career/vacancy-edit"
      ),
    meta: { requiresLogin: true, name: "Carier" },
  },
  {
    path: "/faq/faq-edit/:id",
    name: "faq-edit",
    component: () =>
      import(/* webpackChunkName: "faq-edit" */ "../views/faq/faq-edit"),
    meta: { requiresLogin: true, name: "Benefit" },
  },
  {
    path: "/about/position-edit/:id",
    name: "position-edit",
    component: () =>
      import(
        /* webpackChunkName: "position-edit" */ "../views/about/position-edit"
      ),
    meta: { requiresLogin: true, name: "WhoAreWe" },
  },

  {
    path: "/career/institution-edit/:id",
    name: "institution-edit",
    component: () =>
      import(
        /* webpackChunkName: "institution-edit" */ "../views/career/institution-edit"
      ),
    meta: { requiresLogin: true, name: "Carier" },
  },
  {
    path: "/career/profession-edit/:id",
    name: "profession-edit",
    component: () =>
      import(
        /* webpackChunkName: "profession-edit" */ "../views/career/profession-edit"
      ),
    meta: { requiresLogin: true, name: "Carier" },
  },
  {
    path: "*",
    redirect: "/home",
  },
];
