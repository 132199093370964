<template>
  <section class="services">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Services" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <language @lang="langChanged($event)" />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="serviceTypes"
                  item-text="name"
                  item-value="id"
                  :label="$store.state.statics.Title"
                  dense
                  v-model="serviceType"
                  @change="changeServiceType"
                  outlined
                  background-color="inputs"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" v-show="serviceType">
                <v-row>
                  <v-col cols="8" v-show="lang === '_az'">
                    <label>{{ description("az") }} </label>
                    <vue-editor v-model="service.description_az"></vue-editor>
                  </v-col>
                  <v-col cols="8" v-show="lang === '_de'">
                    <label>{{ description("de") }} </label>
                    <vue-editor v-model="service.description_de"></vue-editor>
                  </v-col>
                  <v-col cols="8" v-show="lang === '_en'">
                    <label>{{ description("en") }} </label>
                    <vue-editor v-model="service.description_en"></vue-editor>
                  </v-col>
                  <v-col cols="8" v-show="lang === '_ru'">
                    <label>{{ description("ru") }} </label>
                    <vue-editor v-model="service.description_ru"></vue-editor>
                  </v-col>
                  <v-col cols="8" v-show="lang === '_tr'">
                    <label>{{ description("tr") }} </label>
                    <vue-editor v-model="service.description_tr"></vue-editor>
                  </v-col>
                  <v-col cols="4">
                    <image-input
                      :image="service.photoUrl"
                      @new-image="changePhoto($event)"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="changeService">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      serviceType: null,
      service: {},
    };
  },
  computed: {
    ...mapState({
      serviceTypes: (state) => state.services.serviceTypes,
    }),
  },
  methods: {
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    changeServiceType() {
      this.$store
        .dispatch("services/getServiceById", this.serviceType)
        .then((res) => {
          this.service = res;
        });
    },
    changeService() {
      var l = this.service;

      let formData = new FormData();
      formData.append("Description_az", l.description_az);
      formData.append("Description_de", l.description_de);
      formData.append("Description_en", l.description_en);
      formData.append("Description_ru", l.description_ru);
      formData.append("Description_tr", l.description_tr);
      formData.append("ServiceTypeId", l.serviceType.id);
      if (l.photo) {
        formData.append("Photo", l.photo);
      }
      formData.append("IsActive", true);

      this.$store.dispatch("services/updateService", { id: l.id, formData });
    },
    changePhoto(e) {
      this.service.photo = e;
    },
  },
  created() {
    this.$store.dispatch("services/getServiceTypes");
  },
  mixins: [lang],
};
</script>