<template>
  <section class="leadership">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Contact" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mb-5">
                <language @lang="langChanged($event)" />
              </v-col>
            </v-row>
            <v-row v-show="lang === '_az'">
              <v-col cols="12"
                ><v-text-field
                  :label="address('az')"
                  dense
                  outlined
                  v-model="contacts.address_az"
                  hide-details
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="phone('az')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.phone_az"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="email('az')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.email_az"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row v-show="lang === '_de'">
              <v-col cols="12"
                ><v-text-field
                  :label="address('de')"
                  dense
                  outlined
                  v-model="contacts.address_de"
                  hide-details
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="phone('de')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.phone_de"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="email('de')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.email_de"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row v-show="lang === '_en'">
              <v-col cols="12"
                ><v-text-field
                  :label="address('en')"
                  dense
                  outlined
                  v-model="contacts.address_en"
                  hide-details
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="phone('en')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.phone_en"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="email('en')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.email_en"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row v-show="lang === '_ru'">
              <v-col cols="12"
                ><v-text-field
                  :label="address('ru')"
                  dense
                  outlined
                  v-model="contacts.address_ru"
                  hide-details
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="phone('ru')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.phone_ru"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="email('ru')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.email_ru"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row v-show="lang === '_tr'">
              <v-col cols="12"
                ><v-text-field
                  :label="address('tr')"
                  dense
                  outlined
                  v-model="contacts.address_tr"
                  hide-details
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="phone('tr')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.phone_tr"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  :label="email('tr')"
                  dense
                  outlined
                  hide-details
                  v-model="contacts.email_tr"
                  background-color="inputs"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="changeContacts">
                  {{$store.state.statics.Approve}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
export default {
  data() {
    return {
      contacts: {},
    };
  },
  created() {
    this.$store.dispatch("contact/getContacts").then((res) => {
      if (res) {
        this.contacts = res;
      }
    });
  },
  methods: {
    address(e) {
      return this.$store.state.statics.Address + " (" + e + ")";
    },
    phone(e) {
      return this.$store.state.statics.Phone + " (" + e + ")";
    },
    email(e) {
      return this.$store.state.statics.Email + " (" + e + ")";
    },
    changeContacts() {
      this.$store.dispatch("contact/setContacts", this.contacts);
    },
  },
  mixins: [lang],
};
</script>