<template>
  <div class="questions-page">
    <questions />
  </div>
</template>
<script>
import Questions from "../../components/questions/Questions.vue";
export default {
  components: {
    Questions,
  },
};
</script>