<template>
  <!-- TODO: silmek -->
  <v-dialog v-model="dialog" width="500" type="success">
    <v-card>
      <v-card-title class="text-h5 red" style="color: white">
        Diqqət
      </v-card-title>

      <v-card-text>
        <p class="mt-5 mb-0">Bu məlumat silinəcək</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="dialog = false"> Ləğv et </v-btn>
        <v-btn color="red" class="warning" @click="accept"> Təsdiqlə </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogBus } from "@/main.js";

export default {
  data() {
    return {
      dialog: false,
      index: null,
      cmpName: null,
    };
  },
  created() {
    dialogBus.$on("removeItem", (e) => {
      this.index = e.index;
      this.cmpName = e.cmp;
      this.dialog = true;
    });
  },
  methods: {
    accept() {
      dialogBus.$emit("accept", {
        cmp: this.cmpName,
        index: this.index,
      });
      this.dialog = false;
    },
  },
};
</script>