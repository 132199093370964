<template>
  <v-app-bar color="white" height="64" class="header">
    <v-container>
      <v-row align="center">
        <v-col cols="6">
          <div class="header__page-title" v-if="$store.state.statics">
            {{ $store.state.statics[currentPage] }}
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="header__right">
            <div class="header__lang">
              <v-select
                :items="languages"
                item-text="name"
                item-value="id"
                :value="active"
                @change="changeLang"
                label="Səhifə"
                solo
                dense
                background-color="inputs"
                hide-selected
                hide-details
                width="50px"
              ></v-select>
            </div>
            <v-btn icon @click="$router.push({ name: 'settings' })">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn icon @click.prevent="logout">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      languages: [
        {
          id: "az",
          name: "AZ",
        },
        {
          id: "de",
          name: "DE",
        },
        {
          id: "en",
          name: "EN",
        },
        {
          id: "ru",
          name: "RU",
        },
        {
          id: "tr",
          name: "TR",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      active: (state) => state.lang,
    }),
    currentPage() {
      return this.$route.meta.name;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    changeLang(e) {
      this.$store.commit("setLangManual", e);
    },
  },
};
</script>
