<template>
  <span>
    {{ truncated }}
  </span>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  computed: {
    truncated() {
      let clamp = "...";
      let node = document.createElement("div");
      node.innerHTML = this.text;
      let content = node.textContent;
      return content.length > this.size
        ? content.slice(0, this.size) + clamp
        : content;
    },
  },
};
</script>