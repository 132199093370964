<template>
  <div class="get-staff-page">
    <GetStaffAppeals />
  </div>
</template>
<script>
import GetStaffAppeals from "../../components/get-staff/GetStaffAppeals.vue";
export default {
  components: {
    GetStaffAppeals,
  },
};
</script>