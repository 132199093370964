import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Faq from "../../adapters/faq-adapter.js";

export default {
  namespaced: true,
  state: {
    faqs: [],
  },

  mutations: {
    setFaqs(state, payload) {
      state.faqs = payload;
    },
    removeFaq(state, payload) {
      state.faqs = state.faqs.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getFaqs({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/benefit`)
        .then((response) => {
          let lang = store.state.lang;
          const res = response.data.data.benefits;
          commit(
            "setFaqs",
            res.map((i) => {
              return new Faq(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addFaq(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/benefit`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteFaq({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/benefit/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeFaq", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/benefit/${id}`)
        .then((response) => {
          const res = response.data.data.benefit;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updateFaq(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/benefit/${payload.id}`, payload)
        .then((response) => {
          const mes = response.data.message;
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
