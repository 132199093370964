import axios from "axios";
import store from "./../index";
import Vue from "vue";
import OfferType from "../../adapters/offer-type-adapter";

export default {
  namespaced: true,
  state: {
    offerTypes: [],
  },

  mutations: {
    setOfferTypes(state, payload) {
      state.offerTypes = payload;
    },
  },
  actions: {
    getOfferTypes({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/suggest/getSuggestTypes`)
        .then((response) => {
          const res = response.data.data.suggestTypes;
          let lang = store.state.lang;
          commit(
            "setOfferTypes",
            res.map((i) => {
              return new OfferType(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    getOfferById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/suggest/GetSuggestBySuggestType/${id}`)
        .then((response) => {
          const res = response.data.data.suggest;
          return res;
        })
        .catch(() => {
          return false;
        });
    },
    updateOffer(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/suggest/${payload.id}`, payload)
        .then((response) => {
          const mes = response.data.message;
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
