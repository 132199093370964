import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#132b59",
        yellow: "#ffc800",
        secondary: "#ccc",
        accent: "#8c9eff",
        error: "#da1f26",
        inputs: "#f9fafb",
        inputs2: "#fefbfb",
        inputs3: "#d6e8d7",
        red: "#da1f26"
      },
    },
  },
});
