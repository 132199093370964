import axios from "axios";
import router from "../../router";
import store from "./../index";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    user: null,
    loginError: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    initAuth() {
      let token = localStorage.getItem("admin.igaplus");
      if (token) {
        store.commit("setToken", token);
        return true;
      } else {
        router.push({ name: "login" });
        return false;
      }
    },
    login(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post("/identity/login", payload)
        .then((response) => {
          const res = response.data;
          let token = res.data.token;
          store.commit("setToken", token);
          localStorage.setItem("admin.igaplus", token);
          localStorage.setItem("admin.igaplus.login", payload.email);
          location.reload();
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    logout() {
      store.commit("removeToken");
      router.push({ name: "login" });
    },

    changePass(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post("/identity/changePassword", payload)
        .then((response) => {
          if (response && response.status === 200) {
            Vue.$toast.success(response.data.message);
            return true;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
};
