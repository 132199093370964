import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Partner from "../../adapters/partner-adapter";

export default {
  namespaced: true,
  state: {
    partners: [],
  },

  mutations: {
    setPartners(state, payload) {
      state.partners = payload;
    },
    removePartner(state, payload) {
      state.partners = state.partners.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getPartners({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/partner`)
        .then((response) => {
          const res = response.data.data.partners;
          let lang = store.state.lang;
          commit(
            "setPartners",
            res.map((i) => {
              return new Partner(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addPartner(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/partner`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deletePartner({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/partner/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removePartner", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/partner/${id}`)
        .then((response) => {
          const res = response.data.data.partner;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updatePartner(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/partner/${payload.id}`, payload.formData)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
