<template>
  <section class="feedback-edit">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.EditFeedback" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row class="mb-3">
              <v-col cols="12">
                <language @lang="langChanged($event)" />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$store.state.statics.Age"
                  v-model="feedback.age"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_az'">
              <v-col cols="12">
                <v-text-field
                  :label="name('az')"
                  v-model="feedback.name_az"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="surname('az')"
                  v-model="feedback.surname_az"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>{{ description("az") }} </label>
                <vue-editor v-model="feedback.description_az"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_de'">
              <v-col cols="12">
                <v-text-field
                  :label="name('de')"
                  v-model="feedback.name_de"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="surname('de')"
                  v-model="feedback.surname_de"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>{{ description("de") }} </label>
                <vue-editor v-model="feedback.description_de"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_en'">
              <v-col cols="12">
                <v-text-field
                  :label="name('en')"
                  v-model="feedback.name_en"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="surname('en')"
                  v-model="feedback.surname_en"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>{{ description("en") }} </label>
                <vue-editor v-model="feedback.description_en"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_ru'">
              <v-col cols="12">
                <v-text-field
                  :label="name('ru')"
                  v-model="feedback.name_ru"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="surname('ru')"
                  v-model="feedback.surname_ru"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>{{ description("ru") }} </label>
                <vue-editor v-model="feedback.description_ru"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_tr'">
              <v-col cols="12">
                <v-text-field
                  :label="name('tr')"
                  v-model="feedback.name_tr"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="surname('tr')"
                  v-model="feedback.surname_tr"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>{{ description("tr") }} </label>
                <vue-editor v-model="feedback.description_tr"></vue-editor>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'home' })"
                >
                  {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="updateFeedback">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
export default {
  data() {
    return {
      feedback: {},
    };
  },
  methods: {
    name(e) {
      return this.$store.state.statics.Name + " (" + e + ")";
    },
    surname(e) {
      return this.$store.state.statics.Surname + " (" + e + ")";
    },
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    updateFeedback() {
      this.$store.dispatch("feedbacks/updateFeedback", this.feedback);
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("feedbacks/getById", id).then((res) => {
      this.feedback = res;
    });
  },
  mixins: [lang],
};
</script>