<template>
  <div class="partners-page">
    <Partners />
  </div>
</template>
<script>
import Partners from "@/components/partners/Partners.vue";
export default {
  name: "PartnersPage",
  components: {
    Partners,
  },
};
</script>