<template>
  <tr class="slider-item pointer no-select" @dblclick="$emit('dialog', index)">
    <td width="150px">
      {{ item.vacationName_az }}
    </td>
    <td width="100px">
      {{ fullname }}
    </td>
    <td width="100px">
      {{ item.email }}
    </td>
    <td width="100px">
      {{ item.phone }}
    </td>
    <td width="100px">
      {{date}}
    </td>
    <td class="table__actions">
      <a :href="item.fileUrl" download target="blank">
        <v-icon small color="primary">mdi-cloud-download</v-icon>
      </a>
    </td>

    <td class="table__actions">
      <v-btn size="sm" icon @click="removeItem">
        <v-icon small color="red">mdi-trash-can</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import {utcToLocale} from "../../functions/utc-to-locale";

export default {
  name: "SliderItem",
  data() {
    return {
      testimage: require("@/assets/images/pages/slider0.jpg"),
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    removeItem() {
       this.$emit("removeItem", this.item.id);
    },
  },
  computed: {
    fullname() {
      return this.item.name + " " + this.item.surname;
    },
    date() {
      let date = this.item.createdDate;
      return utcToLocale(date);
    }
  },
};
</script>
