import axios from "axios";
import store from "./../index";
import Vue from "vue";
import LeadType from "../../adapters/lead-type-adapter";

export default {
  namespaced: true,
  state: {
    leadTypes: [],
  },

  mutations: {
    setLeadTypes(state, payload) {
      state.leadTypes = payload;
    },
  },
  actions: {
    getLeadTypes({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/about/getAboutTypes`)
        .then((response) => {
          const res = response.data.data.aboutTypes;
          let lang = store.state.lang;

          commit(
            "setLeadTypes",
            res.map((i) => {
              return new LeadType(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    getLeadById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/about/GetAboutByAboutType/${id}`)
        .then((response) => {
          const res = response.data.data.about;
          return res;
        })
        .catch(() => {
          return false;
        });
    },
    updateLead(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/about/${payload.id}`, payload.formData)
        .then((response) => {
          const mes = response.data.message;
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
