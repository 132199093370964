<template>
  <section class="get-staff-appeals">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Applies" />
      </v-col>
      <v-col cols="12">
        <v-card v-if="appeals && appeals.length">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ $store.state.statics.ClinicName }}
                  </th>
                  <th>
                    {{ $store.state.statics.StaffAmount }}
                  </th>
                  <th>
                    {{ $store.state.statics.Date }}
                  </th>
                  <th class="table__actions">
                    {{ $store.state.statics.Delete }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <GetStaffAppealsItem
                  v-for="(item, index) in appeals"
                  :key="index"
                  :item="item"
                  :index="index"
                  @dialog="changeDialog($event)"
                  @removeItem="removeDialog($event)"
                />
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <div v-else>
          <empty-alert />
        </div>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="paging.totalPages"
          circle
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMore" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ active.clinicName }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                background-color="inputs"
                hide-details
                readonly
                :value="active.address"
                :label="$store.state.statics.Address"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                dense
                outlined
                background-color="inputs"
                hide-details
                readonly
                :value="active.contactNumber"
                :label="$store.state.statics.Phone"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                dense
                outlined
                background-color="inputs"
                hide-details
                readonly
                :value="active.email"
                :label="$store.state.statics.Email"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                dense
                outlined
                background-color="inputs"
                hide-details
                readonly
                :value="active.name"
                :label="$store.state.statics.Fullname"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogMore = false">
            {{ $store.state.statics.Close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog v-model="dialog" @delete="removeItem" />
  </section>
</template>
<script>
import GetStaffAppealsItem from "./GetStaffAppealsItem.vue";
import remove from "@/mixins/remove.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialogMore: false,
      active: {},
      size: 10,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      appeals: (state) => state.getStaff.appeals,
      paging: (state) => state.getStaff.paging,
    }),
  },
  created() {
    this.getAppeals();
  },
  methods: {
    changeDialog(e) {
      this.active = this.appeals[e];
      this.dialogMore = true;
    },
    removeItem() {
      this.$store
        .dispatch("getStaff/deleteAppeal", this.selected)
        .then((res) => {
          if (res) {
            this.getAppeals();
          }
        });
      this.clearDialog();
    },
    getAppeals() {
      let query = `?PageNumber=${this.page}&PageSize=${this.size}`;
      this.$store.dispatch("getStaff/getAppeals", query);
    },
  },
  watch: {
    page() {
      this.getAppeals();
    },
  },
  components: {
    GetStaffAppealsItem,
  },
  mixins: [remove],
};
</script>
