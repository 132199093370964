<template>
  <v-app>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <Loader v-show="$store.getters.getLoader" />
    </transition>
    <LeftMenu v-if="!isLogin" />
    <v-main>
      <Header v-if="!isLogin" />
      <div class="ma-5">
        <v-slide-x-transition mode="out-in">
          <router-view transition="slide-x-transition" />
        </v-slide-x-transition>
      </div>
    </v-main>
    <delete-dialog />
  </v-app>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import LeftMenu from "@/components/layout/LeftMenu.vue";
import DeleteDialog from "./components/layout/DeleteDialog.vue";
import Loader from "./components/layout/Loader";

export default {
  name: "App",
  computed: {
    isLogin() {
      return this.$route.name == "login";
    },
  },
  components: {
    Header,
    LeftMenu,
    DeleteDialog,
    Loader,
  },
  created() {
    this.$store.dispatch("getLanguage").then((res) => {
      if (res) {
        this.$store.dispatch("getStatics");
      }
    });
  },
};
</script>

