<template>
  <section class="get-staff-appeals">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Applies"/>
      </v-col>
      <v-col cols="12">
        <v-card v-if="appeals && appeals.length">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
              <tr>
                <th>
                  {{ $store.state.statics.Fullname }}
                </th>
                <th>
                  {{ $store.state.statics.Phone }}
                </th>
                <th>
                  {{ $store.state.statics.Date }}
                </th>
                <th class="table__actions">
                  {{ $store.state.statics.Delete }}
                </th>
              </tr>
              </thead>
              <tbody>
              <NurseAppealsItem
                  v-for="(item, index) in appeals"
                  :key="index"
                  :index="index"
                  :item="item"
                  @dialog="changeDialog($event)"
                  @removeItem="removeDialog($event)"
              />
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <div v-else>
          <empty-alert/>
        </div>
      </v-col>
      <v-col cols="12">
        <v-pagination
            v-model="page"
            :length="paging.totalPages"
            circle
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMore" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ fullname }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>

            <v-col cols="12" lg="6">
              <v-text-field
                  :label="$store.state.statics.Email"
                  :value="active.email"
                  background-color="inputs"
                  dense
                  hide-details
                  outlined
                  readonly
              />
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                  :label="$store.state.statics.Phone"
                  :value="active.phone"
                  background-color="inputs"
                  dense
                  hide-details
                  outlined
                  readonly
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                  :label="$store.state.statics.Education"
                  :value="active.education"
                  background-color="inputs"
                  dense
                  hide-details
                  outlined
                  readonly
              />
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                  :label="$store.state.statics.LanguageSkills"
                  :value="active.languageSkills"
                  background-color="inputs"
                  dense
                  hide-details
                  outlined
                  readonly
              />
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                  :label="$store.state.statics.Note"
                  :value="active.comments"
                  background-color="inputs"
                  dense
                  hide-details
                  outlined
                  readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogMore = false">
            {{ $store.state.statics.Close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog v-model="dialog" @delete="removeItem"/>
  </section>
</template>
<script>
import NurseAppealsItem from "./NurseAppealsItem.vue";
import remove from "@/mixins/remove.js";
import {mapState} from "vuex";

export default {
  data() {
    return {
      dialogMore: false,
      active: {},
      size: 10,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      appeals: (state) => state.nurses.appeals,
      paging: (state) => state.nurses.paging,
    }),
    fullname() {
      return this.active.name + " " + this.active.surname;
    }
  },
  created() {
    this.getAppeals();
  },
  methods: {
    changeDialog(e) {
      this.active = this.appeals[e];
      this.dialogMore = true;
    },
    removeItem() {
      this.$store
          .dispatch("nurses/deleteAppeal", this.selected)
          .then((res) => {
            if (res) {
              this.getAppeals();
            }
          });
      this.clearDialog();
    },
    getAppeals() {
      let query = `?PageNumber=${this.page}&PageSize=${this.size}`;
      this.$store.dispatch("nurses/getAppeals", query);
    },
  },
  watch: {
    page() {
      this.getAppeals();
    },
  },
  components: {
    NurseAppealsItem,
  },
  mixins: [remove],
};
</script>
