<template>
  <div class="lead-page">
    <lead />
  </div>
</template>
<script>
import Lead from "../../components/lead/Lead.vue";
export default {
  name: "LeadPage",
  components: {
    Lead,
  },
};
</script>