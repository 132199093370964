<template>
  <div class="career-experience">
    <div class="row">
      <div class="col-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="certificate"
          :label="$store.state.statics.IsMinistryofPublicHealthCertificated"
        />
      </div>

      <div class="col-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.job"
          :label="$store.state.statics.SpecialtyCertification"
        />
      </div>
      <div class="col-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.experience"
          :label="$store.state.statics.WorkExperienceDate"
        />
      </div>

      <div class="col-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.position"
          :label="$store.state.statics.Position"
        />
      </div>

      <div class="col-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.organisationName"
          :label="$store.state.statics.OrganisationName"
        />
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 pb-0">
            <div class="label">
              {{ $store.state.statics.WorkDays }}
            </div>
          </div>
          <div class="col-12">
            <v-textarea
              :value="weekDays"
              readonly
              auto-grow
              dense
              outlined
              rows="0"
              background-color="inputs"
              maxlength="200"
            ></v-textarea>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 pb-0">
            <div class="label">
              {{ $store.state.statics.WorkGraphic }}
            </div>
          </div>
          <div class="col-lg-6">
            <v-text-field
              dense
              outlined
              background-color="inputs"
              hide-details
              readonly
              :value="item.workTimeStart"
              :label="$store.state.statics.StartDate"
            />
          </div>
          <div class="col-lg-6">
            <v-text-field
              dense
              outlined
              background-color="inputs"
              hide-details
              readonly
              :value="item.workTimeEnd"
              :label="$store.state.statics.EndDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    certificate() {
      return this.item.certificate
        ? this.$store.state.statics.Have
        : this.$store.state.statics.Havenot;
    },
    weekDays() {
      let days = this.item.workDays;
      let result = "";
      for (let i = 0; i < days.length; i++) {
        result += days[i].name;
        if (days[i + 1]) {
          result += ", ";
        }
      }
      return result;
    },
  },
};
</script>
