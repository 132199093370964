<template>
  <div class="login-page">
    <v-row justify="center">
      <v-col cols="12" md="3" sm="6">
        <v-card>
          <v-card-text>
            <form @submit.prevent="login">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Login"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-model="user.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Şifrə"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                    v-model="user.password"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn color="primary" type="submit"> Daxil ol </v-btn>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  methods: {
    login() {
      this.$store.dispatch("auth/login", this.user);
    },
  },
};
</script>

