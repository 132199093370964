<template>
  <section class="team">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Team" link="team-add" />
      </v-col>
      <v-col cols="12">
        <v-card v-if="team && team.length">
          <v-data-table
            :headers="headers"
            :items="team"
            disable-sort
            class="table"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100],
              itemsPerPageText: $store.state.statics.DataPerPage,
            }"
          >
            <template v-slot:[`item.image`]="{ item }">
              <img :src="item.image" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                size="sm"
                icon
                @click="
                  $router.push({ name: 'team-edit', params: { id: item.id } })
                "
              >
                <v-icon small>mdi-pen</v-icon>
              </v-btn>

              <v-btn @click="removeDialog(item.id)" size="sm" icon>
                <v-icon small color="red"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <div v-else>
          <empty-alert />
        </div>
      </v-col>
    </v-row>
    <delete-dialog v-model="dialog" @delete="removeItem" />
  </section>
</template>
<script>
import { mapState } from "vuex";
import remove from "@/mixins/remove.js";
export default {
  computed: {
    ...mapState({
      team: (state) => state.team.team,
    }),
    headers() {
      return [
        {
          text: this.$store.state.statics.Photo,
          value: "image",
          cellClass: "slider-item__image",
        },
        { text: this.$store.state.statics.Fullname, value: "fullname" },
        { text: this.$store.state.statics.Position, value: "position" },
        {
          text: this.$store.state.statics.Edit,
          value: "actions",
          align: "end",
        },
      ];
    },
  },
  created() {
    this.$store.dispatch("team/getTeam");
  },
  methods: {
    removeItem() {
      this.$store.dispatch("team/deleteEmployee", this.selected);
      this.clearDialog();
    },
  },
  mixins: [remove],
};
</script>