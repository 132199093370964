<template>
  <div class="section-title">
    <v-row>
      <v-col cols="8">
        {{ title }}
      </v-col>
      <v-col cols="4" v-if="link" class="text-right">
        <v-btn small color="primary" @click="$router.push({ name: link })">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    link: {
      type: String,
      required: false,
    },
  },
};
</script>