<template>
  <div class="career-personal">
    <div class="row">
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.name"
          :label="$store.state.statics.Name"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.surname"
          :label="$store.state.statics.Surname"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.patronymic"
          :label="$store.state.statics.Patronymic"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.district"
          :label="$store.state.statics.Residence"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.phone"
          :label="$store.state.statics.Phone"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.email"
          :label="$store.state.statics.Email"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.birth"
          :label="$store.state.statics.BirthDate"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="family"
          :label="$store.state.statics.FamilyStatus"
        />
      </div>
      <div class="col-lg-6">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="child"
          :label="$store.state.statics.DoYouHaveAChilderen"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: "",
      familyStatus: [
        { text: "Evli", value: "0" },
        { text: "Subay", value: "1" },
      ],
      childrenStatus: [
        { text: "Bəli", value: "0" },
        { text: "Xeyr", value: "1" },
      ],
    };
  },
  computed: {
    family() {
      return this.item.family
        ? this.$store.state.statics.Married
        : this.$store.state.statics.UnMarried;
    },
    child() {
      return this.item.child
        ? this.$store.state.statics.Yes
        : this.$store.state.statics.No;
    },
  },
};
</script>