<template>
  <section class="institution-add">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.AddInstitutions" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row class="mb-5">
              <v-col cols="12">
                <v-text-field
                  :label="name('az')"
                  v-model="institution.name_az"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('de')"
                  v-model="institution.name_de"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('en')"
                  v-model="institution.name_en"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('ru')"
                  v-model="institution.name_ru"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('tr')"
                  v-model="institution.name_tr"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'career' })"
                >
                {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="addInstitution"> {{ $store.state.statics.Approve }} </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      institution: {},
    };
  },
  methods: {
    name(e) {
      return this.$store.state.statics.Institution + " (" + e + ")";
    },
    addInstitution() {
      this.institution.isActive = true;
      this.$store
        .dispatch("institution/addInstitution", this.institution)
        .then((res) => {
          if (res) {
            this.institution = {};
          }
        });
    },
  },
   created() {
    this.$store.dispatch("institution/getInstitutions");
  },
};
</script>