import {utcToLocale} from '../functions/utc-to-locale';

export default class Appeal {
    constructor(item, lang) {
        this._setId(item);
        this._setBirth(item);
        this._setEmail(item);
        this._setCertificate(item);
        this._setExperience(item);
        this._setHasChild(item);
        this._setIsMarried(item);
        this._setName(item);
        this._setSurname(item);
        this._setPatronymic(item);
        this._setPhone(item);
        this._setOrganisationName(item);
        this._setPosition(item);
        this._setDistrict(item);
        this._setCreatedDate(item);

        this._setInstitution(item.institution, lang);
        this._setJob(item.jobSpecialty, lang);
        this._setProfession(item.profession, lang);
        this._setLanguages(item.carierLanguages, lang);
        this._setWorkDays(item.carierWorkDaysOfTheWeeks, lang);
        //
        this._setEducationStartDate(item);
        this._setEducationEndDate(item);
        this._setWorkTimeStart(item);
        this._setWorkTimeEnd(item);

        this._modifyFullname(item);
    }

    /**
     * set id
     * @param {id} param0
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set birth
     * @param {birthDate} param0
     */
    _setBirth({birthDate}) {
        let birth = birthDate.split('T')[0];

        if (birth) {
            this.birth = birth;
        } else {
            this.birth = birthDate;
        }
        if (birthDate === '0001-01-01T00:00:00') {
            this.birth = '';
        }

    }

    /**
     * set email
     * @param {email} param0
     */
    _setEmail({email}) {
        this.email = email;
    }

    /**
     * set certificate
     * @param {isMinistryofPublicHealthCertificated} param0
     */
    _setCertificate({isMinistryofPublicHealthCertificated}) {
        this.certificate = isMinistryofPublicHealthCertificated;
    }

    /**
     * set experience
     * @param {experience} param0
     */
    _setExperience({experience}) {
        this.experience = experience;
    }

    /**
     * set hasChild
     * @param {isHaveChild} param0
     */
    _setHasChild({isHaveChild}) {
        this.hasChild = isHaveChild;
    }

    /**
     * set isMarried
     * @param {isMarried} param0
     */
    _setIsMarried({isMarried}) {
        this.isMarried = isMarried;
    }

    /**
     * set name
     * @param {name} param0
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set surname
     * @param {surname} param0
     */
    _setSurname({surname}) {
        this.surname = surname;
    }

    /**
     * set patronymic
     * @param {patronymic} param0
     */
    _setPatronymic({patronymic}) {
        this.patronymic = patronymic;
    }

    /**
     * set phone
     * @param {phone} param0
     */
    _setPhone({phone}) {
        this.phone = phone;
    }

    /**
     * set organisationName
     * @param {organisationName} param0
     */
    _setOrganisationName({organisationName}) {
        this.organisationName = organisationName;
    }

    /**
     * set position
     * @param {position} param0
     */
    _setPosition({position}) {
        this.position = position;
    }

    /**
     * set district
     * @param {district} item
     */
    _setDistrict({district}) {
        this.district = district;
    }

    /**
     * set institution
     * @param {institution} item
     * @param {language} lang
     */
    _setInstitution(item, lang) {
        let key = 'name_' + lang;
        this.institution = item ? item[key] : '';
    }

    /**
     * set job
     * @param {jobSpecialty} item
     * @param {language} lang
     */
    _setJob(item, lang) {
        let key = 'name_' + lang;
        this.job = item ? item[key] : '';
    }

    /**
     * set profession
     * @param {profession} item
     * @param {language} lang
     */
    _setProfession(item, lang) {
        let key = 'name_' + lang;
        this.profession = item ? item[key] : '';
    }

    /**
     * set languages
     * @param {languages} item
     * @param {language} lang
     */
    _setLanguages(item, lang) {
        let key = 'name_' + lang;
        let items = [];
        item.forEach((i) => {
            let obj = {
                name: i.languageName,
                level: i.languageLevel[key],
            };
            items.push(obj);
        });

        this.languages = items;
    }

    /**
     * set workdays
     * @param {workdays} item
     * @param {language} lang
     */
    _setWorkDays(item, lang) {
        let key = 'name_' + lang;
        let items = [];
        item.forEach((i) => {
            let obj = {
                id: i.id,
                name: i.workDaysOfTheWeek[key],
            };
            items.push(obj);
        });

        this.workDays = items;
    }

    /**
     * set work time start
     * @param {startTimeLastWork} param0
     */
    _setWorkTimeStart({startTimeLastWork}) {
        let s = startTimeLastWork % 60;
        let start = Math.floor(startTimeLastWork / 60) + ':' + s;
        if (s < 10) {
            start += '0';
        }
        if (start === '0:00')
            this.workTimeStart = '';
        else
            this.workTimeStart = start;

    }

    /**
     * set work time end
     * @param {endTimeLastWork} param0
     */
    _setWorkTimeEnd({endTimeLastWork}) {
        let e = endTimeLastWork % 60;
        let end = Math.floor(endTimeLastWork / 60) + ':' + e;
        if (e < 10) {
            end += '0';
        }

        if (end === '0:00')
            this.workTimeEnd = '';
        else
            this.workTimeEnd = end;
    }

    /**
     * set education start
     * @param {startDateHigherEducation} param0
     */
    _setEducationStartDate({startDateHigherEducation}) {
        let edu = startDateHigherEducation.split('T')[0];
        let r = '';
        if (edu) {
            r = edu;
        } else {
            r = startDateHigherEducation;
        }
        if (r === '0001-01-01')
            this.eduStart = '';
        else
            this.eduStart = r;
    }

    /**
     * set education end
     * @param {endDateHigherEducation} param0
     */
    _setEducationEndDate({endDateHigherEducation}) {
        let edu = endDateHigherEducation.split('T')[0];
        let r = '';
        if (edu) {
            r = edu;
        } else {
            r = endDateHigherEducation;
        }
        if (r === '0001-01-01')
            this.eduEnd = '';
        else
            this.eduEnd = r;
    }

    /**
     * modify fullname
     * @param {name,surname} param0
     */
    _modifyFullname({name, surname}) {
        this.fullname = name + ' ' + surname;
    }

    _setCreatedDate({createdDate}) {
        this.date = utcToLocale(createdDate);
    }
}
