var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"partners"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('section-title',{attrs:{"title":_vm.$store.state.statics.Partners,"link":"partner-add"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.partners && _vm.partners.length)?_c('v-card',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.partners,"disable-sort":"","footer-props":{
            'items-per-page-options': [10, 25, 50, 100],
            itemsPerPageText: _vm.$store.state.statics.DataPerPage,
          }},scopedSlots:_vm._u([{key:"item.photoUrl",fn:function(ref){
          var item = ref.item;
return [_c('img',{attrs:{"src":item.photoUrl}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'partner-edit',
                  params: { id: item.id },
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1),_c('v-btn',{attrs:{"size":"sm","icon":""},on:{"click":function($event){return _vm.removeDialog(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1):_c('div',[_c('empty-alert')],1)],1)],1),_c('delete-dialog',{on:{"delete":_vm.removeItem},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }