export default class Institution {
  constructor(item, lang) {
    this._setId(item);
    this._setName(item, lang);
  }

  /**
   * set id
   * @param {id} param0
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * set name
   * @param {district} item
   * @param {language} lang
   */
  _setName(item, lang) {
    let key = "name_" + lang;
    this.name = item[key];
  }
}
