<template>
  <section class="team-add">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.AddEmployee" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <language @lang="langChanged($event)" />
              </v-col>
              <v-col cols="12" class="text-right">
                <v-switch
                  v-model="employee.isChief"
                  :label="$store.state.statics.Chief"
                ></v-switch>
              </v-col>

              <v-col cols="12">
                <v-select
                  :items="positions"
                  item-text="name"
                  item-value="id"
                  v-model="employee.positionId"
                  :label="$store.state.statics.Position"
                  dense
                  outlined
                  background-color="inputs"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col cols="8">
                <v-row v-show="lang === '_az'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('az')"
                      v-model="employee.name_az"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="surname('az')"
                      v-model="employee.surname_az"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="employee.isChief === true">
                    <label>{{ description("az") }} </label>
                    <vue-editor v-model="employee.description_az"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_de'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('de')"
                      v-model="employee.name_de"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="surname('de')"
                      v-model="employee.surname_de"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="employee.isChief === true">
                    <label>{{ description("de") }} </label>
                    <vue-editor v-model="employee.description_de"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_en'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('en')"
                      v-model="employee.name_en"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="surname('en')"
                      v-model="employee.surname_en"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="employee.isChief === true">
                    <label>{{ description("en") }} </label>
                    <vue-editor v-model="employee.description_en"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_ru'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('ru')"
                      v-model="employee.name_ru"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="surname('ru')"
                      v-model="employee.surname_ru"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="employee.isChief === true">
                    <label>{{ description("ru") }} </label>
                    <vue-editor v-model="employee.description_ru"></vue-editor>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_tr'">
                  <v-col cols="12">
                    <v-text-field
                      :label="name('tr')"
                      v-model="employee.name_tr"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="surname('tr')"
                      v-model="employee.surname_tr"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="employee.isChief === true">
                    <label>{{ description("tr") }} </label>
                    <vue-editor v-model="employee.description_tr"></vue-editor>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <image-input-add
                  :refresh="resfresh"
                  @new-image="changePhoto($event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'about' })"
                >
                  {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="addEmployee">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
import { mapState } from "vuex";
import {objectToFormData} from '@/functions/to-form-data';
export default {
  data() {
    return {
      employee: {
        isChief: false,
      },
      resfresh: true,
    };
  },
  computed: {
    ...mapState({
      positions: (state) => state.positions.positions,
    }),
  },
  methods: {
    name(e) {
      return this.$store.state.statics.Name + " (" + e + ")";
    },
    surname(e) {
      return this.$store.state.statics.Surname + " (" + e + ")";
    },
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    addEmployee() {
      var l = this.employee;
      this.$store.dispatch("team/addEmployee", objectToFormData(l)).then((res) => {
        if (res) {
          this.resfresh = true;
          this.employee = {
            isChief: false,
          };
        }
      });
    },
    changePhoto(e) {
      this.resfresh = false;
      this.employee.photo = e;
    },
  },
  created() {
    this.$store.dispatch("positions/getPositions");
  },

  mixins: [lang],
};
</script>