<template>
  <section class="appeals">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Applies" />
      </v-col>
      <v-col cols="12">
        <filters @search="filtering($event)" />
      </v-col>
      <v-col cols="12">
        <v-card v-if="appeals && appeals.length">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ $store.state.statics.User }}
                  </th>
                  <th>{{ $store.state.statics.Email }}</th>
                  <th>
                    {{ $store.state.statics.Phone }}
                  </th>
                  <th>
                    {{ $store.state.statics.BirthDate }}
                  </th>
                  <th>
                    {{ $store.state.statics.Date }}
                  </th>
                  <th class="table__actions">
                    {{ $store.state.statics.Delete }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <work-item
                  v-for="(item, index) in appeals"
                  :key="index"
                  :item="item"
                  :index="index"
                  @dialog="changeDialog($event)"
                  @removeItem="removeDialog($event)"
                />
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <div v-else>
          <empty-alert />
        </div>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="paging.totalPages"
          circle
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMore" width="1000" top scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ active.fullname }}
        </v-card-title>

        <v-card-text>
          <v-tabs fixed-tabs v-model="tab">
            <v-tab v-for="(item, index) in items" :key="index">
              {{ $store.state.statics[item] }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <PersonalInfo :item="active" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Education :item="active" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <Experience :item="active" />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogMore = false">
            {{ $store.state.statics.Close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog v-model="dialog" @delete="removeItem" />
  </section>
</template>
<script>
import PersonalInfo from "./work-form/PersonalInfo.vue";
import Education from "./work-form/Education.vue";
import Experience from "./work-form/Experience.vue";
import WorkItem from "./WorkItem.vue";
import { mapState } from "vuex";
import Filters from "./Filters.vue";
import remove from "@/mixins/remove.js";
export default {
  data() {
    return {
      dialogMore: false,
      active: {},
      tab: null,
      size: 10,
      page: 1,
      items: ["PrivateInformation", "Education", "ProfessionalActivity"],
      cmps: ["PersonalInfo", "Education", "Experience"],
      filter: "",
    };
  },
  computed: {
    ...mapState({
      appeals: (state) => state.work.appeals,
      paging: (state) => state.work.paging,
    }),
  },
  methods: {
    changeDialog(e) {
      this.active = this.appeals[e];
      this.dialogMore = true;
    },
    removeItem() {
      this.$store.dispatch("work/deleteAppeal", this.selected).then((res) => {
        if (res) {
          this.getAppeals();
        }
      });
      this.clearDialog();
    },
    getAppeals() {
      let query =
        `?PageNumber=${this.page}&PageSize=${this.size}` + this.filter;
      this.$store.dispatch("work/getAppeals", query);
    },
    filtering(e) {
      this.filter = e;
      this.page = 1;
      this.getAppeals();
    },
  },
  created() {
    this.getAppeals();
  },
  watch: {
    page() {
      this.getAppeals();
    },
  },
  components: {
    WorkItem,
    PersonalInfo,
    Education,
    Experience,
    Filters,
  },
  mixins: [remove],
};
</script>
