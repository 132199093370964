import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Slider from "../../adapters/slider-adapter.js";

export default {
  namespaced: true,
  state: {
    sliders: [],
  },

  mutations: {
    setSliders(state, payload) {
      state.sliders = payload;
    },
    removeSlider(state, payload) {
      state.sliders = state.sliders.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getSliders({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/carousel`)
        .then((response) => {
          const res = response.data.data.carouselPhotos;
          let lang = store.state.lang;
          commit(
            "setSliders",
            res.map((s) => {
              return new Slider(s, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addSlider(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/carousel`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteSlider({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/carousel/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeSlider", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/carousel/${id}`)
        .then((response) => {
          const res = response.data.data.carouselPhoto;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updateSlider(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/carousel/${payload.id}`, payload.formData)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
