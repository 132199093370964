<template>
  <v-navigation-drawer permanent app color="primary" dark>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title
            class="white--text font-weight-bold text-center headline"
          >
            <div class="left-menu__logo">
              <img src="@/assets/images/logo2.svg" />
              IGAplus
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider class="left-menu__hr" color="white"></v-divider>

    <v-list dense shaped>
      <v-list-item-group color="primary" v-if="$store.state.statics">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="{ name: item.link }"
          active-class="white--text left-menu__active"
          :class="{ 'left-menu__last': i == 7 }"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.statics[item.title] }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: "HomePage",
          icon: "mdi-home",
          link: "home",
        },
        { title: "WhoAreWe", icon: "mdi-account-group", link: "about" },

        {
          title: "About",
          icon: "mdi-information-variant",
          link: "services",
        },
        {
          title: "Partners",
          icon: "mdi-handshake",
          link: "partners",
        },
        {
          title: "Carier",
          icon: "mdi-briefcase",
          link: "career",
        },
        { title: "Contact", icon: "mdi-email", link: "contact" },
        {
          title: "Benefit",
          icon: "mdi-frequently-asked-questions",
          link: "faq",
        },
        {
          title: "PageDescription",
          icon: "mdi-image-size-select-actual",
          link: "lead",
        },
        { title: "Questions", icon: "mdi-file-document", link: "questions" },
        { title: "VacancyApply", icon: "mdi-file-document", link: "work" },
        { title: "ClinicAppeals", icon: "mdi-file-document", link: "get-staff" },
        { title: "Vacancies", icon: "mdi-file-document", link: "vacancies" },
        { title: "Nurses", icon: "mdi-file-document", link: "nurses" },
      ],
    };
  },
};
</script>
