<template>
  <div class="career-education">
    <div class="row">
      <div class="col-lg-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.institution"
          :label="$store.state.statics.HighInstitution"
        />
      </div>
      <div class="col-lg-12">
        <v-text-field
          dense
          outlined
          background-color="inputs"
          hide-details
          readonly
          :value="item.profession"
          :label="$store.state.statics.ProfessionOfEducation"
        />
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 pb-0">
            <div class="label">
              {{ $store.state.statics.EducationYear }}
            </div>
          </div>
          <div class="col-lg-6">
            <v-text-field
              dense
              outlined
              background-color="inputs"
              hide-details
              readonly
              :value="item.eduStart"
              :label="$store.state.statics.StartDate"
            />
          </div>
          <div class="col-lg-6">
            <v-text-field
              dense
              outlined
              background-color="inputs"
              hide-details
              readonly
              :value="item.eduEnd"
              :label="$store.state.statics.EndDate"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12 pb-0">
            <div class="label">
              {{ $store.state.statics.LanguageSkills }}
            </div>
          </div>
          <div class="col-12">
            <div class="row" v-for="(i, index) in item.languages" :key="index">
              <div class="col-lg-6">
                <v-text-field
                  dense
                  outlined
                  background-color="inputs"
                  hide-details
                  readonly
                  :value="i.name"
                  :label="$store.state.statics.Language"
                />
              </div>
              <div class="col-lg-6">
                <v-text-field
                  dense
                  outlined
                  background-color="inputs"
                  hide-details
                  readonly
                  :value="i.level"
                  :label="$store.state.statics.Level"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>