export default class Faq {
  constructor(item, lang) {
    this._setId(item);
    this._setQuestion(item, lang);
    this._setAnswer(item, lang);
  }

  /**
   * set id
   * @param {id} param0
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * set question
   * @param {vacancy} item
   * @param {language} lang
   */
  _setQuestion(item, lang) {
    let key = "title_" + lang;
    this.title = item[key];
  }

  /**
   * set answer
   * @param {vacancy} item
   * @param {language} lang
   */
  _setAnswer(item, lang) {
    let key = "description_" + lang;
    this.description = item[key];
  }
}
