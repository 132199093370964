import axios from "axios";
import store from "./../index";
import Vue from "vue";

export default {
  namespaced: true,
  actions: {
    getContacts() {
      store.commit("setLoader", true);
      return axios
        .get(`/contact`)
        .then((response) => {
          const res = response.data.data.contacts;
          return res[0];
        })
        .catch(() => {
          return false;
        });
    },
    setContacts(_context, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/contact/${payload.id}`, payload)
        .then((res) => {
          Vue.$toast.success(res.data.message);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
