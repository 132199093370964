<template>
  <div class="vacancies-page">
    <vacancy-apply />
  </div>
</template>
<script>
import VacancyApply from "../../components/vacancies/VacancyApply.vue";
export default {
  components: {
    VacancyApply,
  },
};
</script>