<template>
  <div class="services-page">
    <Services />
  </div>
</template>
<script>
import Services from "@/components/services/Services.vue";
export default {
  name: "ServicesPage",
  components: {
    Services,
  },
};
</script>