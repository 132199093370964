<template>
  <div class="settings-page">
    <v-row justify="center">
      <v-col cols="12" lg="4">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$store.state.statics.CurrentPassword"
                  v-model="user.currentPassword"
                  dense
                  outlined
                  hide-details
                  type="password"
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$store.state.statics.NewPassword"
                  v-model="user.newPassword"
                  type="password"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$store.state.statics.ConfirmNewPassword"
                  v-model="user.confirmNewPassword"
                  type="password"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="changePass">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  methods: {
    changePass() {
      let email = this.$store.getters.getEmail;
      if (
        this.user.currentPassword &&
        this.user.newPassword &&
        this.user.confirmNewPassword
      ) {
        if (this.user.newPassword == this.user.confirmNewPassword) {
          this.$store
            .dispatch("auth/changePass", {
              ...this.user,
              email: email,
            })
            .then((res) => {
              if (res) {
                this.user = {};
              }
            });
        } else {
          let errMessage = "Təkrar şifrə səhvdir";
          this.$toast.error(errMessage);
        }
      }
    },
  },
};
</script>