<template>
  <section class="video">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Video"/>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row class="mb-3">
              <v-col cols="12" class="mb-5">
                <language @lang="langChanged($event)"/>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    :label="$store.state.statics.VideoName"
                    v-model="video.videoName"
                    dense
                    outlined
                    background-color="inputs"
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <image-input
                    :image="video.photoUrl"
                    @new-image="changePhoto($event)"
                />
              </v-col>
              <v-col cols="6">
                <video-input
                    :image="video.videoUrl"
                    @new-video="changeVideo($event)"
                />
              </v-col>
            </v-row>
            <v-row v-show="lang === '_az'">
              <v-col cols="12">
                <v-text-field
                    :label="title('az')"
                    v-model="video.title_az"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    :label="description('az')"
                    v-model="video.description_az"
                    dense
                    outlined
                    background-color="inputs"
                    counter
                    maxlength="200"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_de'">
              <v-col cols="12">
                <v-text-field
                    :label="title('de')"
                    v-model="video.title_de"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    :label="description('de')"
                    v-model="video.description_de"
                    dense
                    outlined
                    background-color="inputs"
                    counter
                    maxlength="200"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_en'">
              <v-col cols="12">
                <v-text-field
                    :label="title('en')"
                    v-model="video.title_en"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    :label="description('en')"
                    v-model="video.description_en"
                    dense
                    outlined
                    background-color="inputs"
                    counter
                    maxlength="200"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_ru'">
              <v-col cols="12">
                <v-text-field
                    :label="title('ru')"
                    v-model="video.title_ru"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    :label="description('ru')"
                    v-model="video.description_ru"
                    dense
                    outlined
                    background-color="inputs"
                    counter
                    maxlength="200"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_tr'">
              <v-col cols="12">
                <v-text-field
                    :label="title('tr')"
                    v-model="video.title_tr"
                    dense
                    outlined
                    hide-details
                    background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    :label="description('tr')"
                    v-model="video.description_tr"
                    dense
                    outlined
                    background-color="inputs"
                    counter
                    maxlength="200"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="updateVideo"> {{ $store.state.statics.Approve }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
  </section>
</template>
<script>
import lang from '@/mixins/lang.js';
import VideoInput from './VideoInput.vue';
import {objectToFormData} from '@/functions/to-form-data';

export default {
  components: {VideoInput},
  data() {
    return {
      video: {},
    };
  },
  methods: {
    title(e) {
      return this.$store.state.statics.Title + ' (' + e + ')';
    },
    description(e) {
      return this.$store.state.statics.Description + ' (' + e + ')';
    },
    updateVideo() {
      var l = this.video;

      this.$store.dispatch('video/updateVideo', {id: l.id, formData: objectToFormData(l)});
    },
    changePhoto(e) {
      this.video.photo = e;
    },
    changeVideo(e) {
      this.video.video = e;
    },
  },
  computed: {},
  created() {
    this.$store.dispatch('video/getVideo').then((res) => {
      this.video = res;
    });
  },
  mixins: [lang],
};
</script>