<template>
  <div class="home">
    <Slider />
    <Offer />
    <VideoRow />
    <Feedbacks />
  </div>
</template>
<script>
import Feedbacks from "./../../components/home/Feedbacks.vue";
import VideoRow from "./../../components/home/VideoRow.vue";
import Offer from "./../../components/home/Offer.vue";
import Slider from "./../../components/home/Slider.vue";
export default {
  name: "Home",
  components: {
    Slider,
    Offer,
    VideoRow,
    Feedbacks,
  },
};
</script>