export const checkIfObject = (data) => {
    return typeof data === 'object' &&
        !Array.isArray(data) && data !== null;
};
export const isFile = (data) => {
    return !!(data.path && data.name && data.size && data.type);
};
export const objectToFormData = function (obj, form, namespace, noIndex) {

    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {

            if (namespace) {
                if (!isNaN(+property)) {
                    formKey = namespace + '[' + property + ']';
                } else formKey = namespace + '.' + property;
            } else {
                formKey = property;
            }
            if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], fd, formKey, noIndex);
            } else if (Array.isArray(obj[property])) {
                obj[property].forEach((item, index) => {
                    if (checkIfObject(item) && !isFile(item)) {
                        for (const partObjKey in item) {
                            if (noIndex) {
                                fd.append(formKey + `[${index}]`, item[partObjKey]);
                            } else {
                                fd.append(formKey + `[${index}].${partObjKey}`, item[partObjKey]);
                            }
                        }
                    } else {
                        if (isFile(item)) {
                            fd.append(formKey, item);
                        } else {
                            fd.append(formKey + `[${index}]`, item);
                        }
                    }

                });
            } else {
                if (obj[property])
                    fd.append(formKey, obj[property]);
            }

        }
    }

    return fd;

};
