<template>
  <v-select
      :items="items"
      item-text="name"
      item-value="id"
      :label="getLanguage"
      dense
      v-model="langId"
      @change="changeLang"
      outlined
      background-color="inputs3"
      hide-details
  ></v-select>
</template>
<script>
export default {
  data() {
    return {
      langId: 1,
      items: [
        {
          id: 1,
          name: 'az',
        },
        {
          id: 2,
          name: 'de',
        },
        {
          id: 3,
          name: 'en',
        },
        {
          id: 4,
          name: 'ru',
        },
        {
          id: 5,
          name: 'tr',
        },
      ],
    };
  },
  computed: {
    getLanguage() {
      return this.$store.state.statics.Langauge;
    }
  },
  methods: {
    changeLang() {
      let l;
      switch (this.langId) {
        case 1:
          l = '_az';
          break;
        case 2:
          l = '_de';
          break;
        case 3:
          l = '_en';
          break;
        case 4:
          l = '_ru';
          break;
        case 5:
          l = '_tr';
          break;
        default:
          l = '_az';
          break;
      }
      this.$emit('lang', l);
    },
  },
};
</script>