<template>
  <div class="work-page">
    <work />
  </div>
</template>
<script>
import Work from "../../components/work/Work.vue";
export default {
  components: {
    Work,
  },
};
</script>