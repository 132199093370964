import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { VueEditor } from "vue2-editor";

import "./assets/styles/main.scss";
import SectionTitle from "@/components/shared/SectionTitle";
import EmptyAlert from "@/components/shared/EmptyAlert";
import Truncate from "@/components/shared/Truncate";
import Language from "@/components/shared/Language";
import ImageInput from "@/components/shared/ImageInput";
import ImageInputAdd from "@/components/shared/ImageInputAdd";
import DeleteDialog from "@/components/shared/DeleteDialog";

Vue.config.productionTip = false;

Vue.component("empty-alert", EmptyAlert);
Vue.component("section-title", SectionTitle);
Vue.component("truncate", Truncate);
Vue.component("image-input-add", ImageInputAdd);
Vue.component("image-input", ImageInput);
Vue.component("language", Language);
Vue.component("delete-dialog", DeleteDialog);
Vue.component("vue-editor", VueEditor);
Vue.use(VueToast);

export const dialogBus = new Vue();

axios.defaults.baseURL = "https://api.igaplus.de/api/v1";
// axios.defaults.baseURL = "http://test.igaplus.de/api/v1";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("admin.igaplus");

axios.interceptors.response.use(
  (response) => {
    store.commit("setLoader", false);
    return response;
  },
  (error) => {
    const {
      response: { status, data },
    } = error;

    console.log("Error: ", error.response);
    let errMessage = "Xəta baş verdi";

    if (router.currentRoute.name == "login") {
      if (data && data.data) {
        errMessage = data.data.errors[0];
      } else {
        errMessage = data.ValidationErrors[0].Message;
      }
    } else {
      if (status === 401) {
        errMessage = "Sessiya müddəti bitmişdir";
        store.commit("removeToken");
        router.push({ name: "login" });
      }
      if (status === 400 || status === 422) {
        if (data.message) {
          errMessage = data.message;
        } else {
          errMessage = data.ValidationErrors[0].Message;
        }
      }
      if (status === 404) {
        if (data.message) {
          errMessage = data.message;
        }
      }
    }
    Vue.$toast.error(errMessage);
    store.commit("setLoader", false);
  }
);
axios.defaults.params = {};
axios.interceptors.request.use(function (config) {
    config.params['culture'] = localStorage.getItem("igaplus-admin-language");
    return config;
}, function (error) {
    return Promise.reject(error);
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
