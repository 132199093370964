<template>
  <div class="faq-page">
    <Faq />
  </div>
</template>
<script>
import Faq from "@/components/faq/Faq.vue";
export default {
  name: "FaqPage",
  components: {
    Faq,
  },
};
</script>