<template>
  <v-dialog :value="value" @input="close" width="500" type="success">
    <v-card>
      <v-card-title class="text-h5 red" style="color: white">
        Diqqət
      </v-card-title>

      <v-card-text>
        <p class="mt-5 mb-0">Bu məlumat silinəcək</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="close"> Ləğv et </v-btn>
        <v-btn color="red" class="warning" @click="accept"> Təsdiqlə </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  methods: {
    accept() {
      this.$emit("delete");
    },
    close() {
      this.$emit("input", false);
    },
  },
};
</script>