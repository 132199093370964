<template>
  <section class="profession-edit">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.EditProfessions" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row class="mb-5">
              <v-col cols="12">
                <v-text-field
                  :label="name('az')"
                  v-model="profession.name_az"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('de')"
                  v-model="profession.name_de"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('en')"
                  v-model="profession.name_en"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('ru')"
                  v-model="profession.name_ru"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="name('tr')"
                  v-model="profession.name_tr"
                  dense
                  outlined
                  hide-details
                  background-color="inputs"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'career' })"
                >
                {{ $store.state.statics.Back }} 
                </v-btn>
                <v-btn color="primary" @click="updateProfession">
                  {{ $store.state.statics.Approve }} 
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      profession: {},
    };
  },
  methods: {name(e) {
      return this.$store.state.statics.Profession + " (" + e + ")";
    },
    updateProfession() {
      this.$store.dispatch("profession/updateProfession", this.profession);
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("profession/getById", id).then((res) => {
      this.profession = res;
    });
    this.$store.dispatch("profession/getProfessions");
  },
};
</script>