<template>
  <section class="questions">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Applies" />
      </v-col>
      <v-col cols="12">
        <v-card v-if="questions && questions.length">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ $store.state.statics.User }}
                  </th>
                  <th>
                    {{ $store.state.statics.Email }}
                  </th>
                  <th>
                    {{ $store.state.statics.Phone }}
                  </th>
                  <th>
                    {{ $store.state.statics.Date }}
                  </th>
                  <th class="table__actions">
                    {{ $store.state.statics.Delete }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <question-item
                  v-for="(item, index) in questions"
                  :key="index"
                  :item="item"
                  :index="index"
                  @dialog="changeDialog($event)"
                  @removeItem="removeDialog($event)"
                />
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <div v-else>
          <empty-alert />
        </div>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="paging.totalPages"
          circle
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMore" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ active.name }} {{ active.surname }}
        </v-card-title>

        <v-card-text class="pt-5">
          {{ active.message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogMore = false">
            {{ $store.state.statics.Close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog v-model="dialog" @delete="removeItem" />
  </section>
</template>
<script>
import QuestionItem from "./QuestionItem.vue";
import remove from "@/mixins/remove.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialogMore: false,
      active: {},
      size: 10,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      questions: (state) => state.questions.questions,
      paging: (state) => state.questions.paging,
    }),
  },
  created() {
    this.getQuestions();
  },
  methods: {
    changeDialog(e) {
      this.active = this.questions[e];
      this.dialogMore = true;
    },
    removeItem() {
      this.$store
        .dispatch("questions/deleteQuestion", this.selected)
        .then((res) => {
          if (res) {
            this.getQuestions();
          }
        });
      this.clearDialog();
    },
    getQuestions() {
      let query = `?PageNumber=${this.page}&PageSize=${this.size}`;
      this.$store.dispatch("questions/getQuestions", query);
    },
  },
  watch: {
    page() {
      this.getQuestions();
    },
  },
  components: {
    QuestionItem,
  },
  mixins: [remove],
};
</script>
