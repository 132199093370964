import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Profession from "../../adapters/profession-adapter";

export default {
  namespaced: true,
  state: {
    professions: [],
  },

  mutations: {
    setProfessions(state, payload) {
      state.professions = payload;
    },
    removeProfession(state, payload) {
      state.professions = state.professions.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getProfessions({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/profession`)
        .then((response) => {
          const res = response.data.data.professions;
          let lang = store.state.lang;
          commit(
            "setProfessions",
            res.map((i) => {
              return new Profession(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addProfession(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/profession`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteProfession({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/profession/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeProfession", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/profession/${id}`)
        .then((response) => {
          const res = response.data.data.profession;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updateProfession(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/profession/${payload.id}`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
