<template>
  <section class="faq-edit">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.EditQuestion" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mb-5">
                <language @lang="langChanged($event)" />
              </v-col>
            </v-row>
            <v-row v-show="lang === '_az'">
              <v-col cols="12">
                <v-textarea
                  :label="question('az')"
                  v-model="faq.title_az"
                  dense
                  outlined
                  background-color="inputs"
                  counter
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label>{{ answer("az") }} </label>
                <vue-editor v-model="faq.description_az"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_de'">
              <v-col cols="12">
                <v-textarea
                  :label="question('de')"
                  v-model="faq.title_de"
                  dense
                  outlined
                  background-color="inputs"
                  counter
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label>{{ answer("de") }} </label>
                <vue-editor v-model="faq.description_de"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_en'">
              <v-col cols="12">
                <v-textarea
                  :label="question('en')"
                  v-model="faq.title_en"
                  dense
                  outlined
                  background-color="inputs"
                  counter
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label>{{ answer("en") }} </label>
                <vue-editor v-model="faq.description_en"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_ru'">
              <v-col cols="12">
                <v-textarea
                  :label="question('ru')"
                  v-model="faq.title_ru"
                  dense
                  outlined
                  background-color="inputs"
                  counter
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label>{{ answer("ru") }} </label>
                <vue-editor v-model="faq.description_ru"></vue-editor>
              </v-col>
            </v-row>
            <v-row v-show="lang === '_tr'">
              <v-col cols="12">
                <v-textarea
                  :label="question('tr')"
                  v-model="faq.title_tr"
                  dense
                  outlined
                  background-color="inputs"
                  counter
                  maxlength="200"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label>{{ answer("tr") }} </label>
                <vue-editor v-model="faq.description_tr"></vue-editor>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'faq' })"
                >
                  {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="updateFaq">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
export default {
  data() {
    return {
      faq: {},
    };
  },
  methods: {
    question(e) {
      return this.$store.state.statics.Question + " (" + e + ")";
    },
    answer(e) {
      return this.$store.state.statics.Answer + " (" + e + ")";
    },
    updateFaq() {
      this.$store.dispatch("faq/updateFaq", this.faq);
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("faq/getById", id).then((res) => {
      this.faq = res;
    });
  },
  mixins: [lang],
};
</script>