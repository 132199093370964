import axios from "axios";
import store from "./../index";
import Vue from "vue";
import Employee from "../../adapters/employee-adapter";

export default {
  namespaced: true,
  state: {
    team: [],
  },

  mutations: {
    setTeam(state, payload) {
      state.team = payload;
    },
    removeEmployee(state, payload) {
      state.team = state.team.filter((x) => {
        return x.id != payload;
      });
    },
  },
  actions: {
    getTeam({ commit }) {
      store.commit("setLoader", true);
      return axios
        .get(`/employee`)
        .then((response) => {
          const res = response.data.data.employees;
          let lang = store.state.lang;
          commit(
            "setTeam",
            res.map((i) => {
              return new Employee(i, lang);
            })
          );
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    addEmployee(_, payload) {
      store.commit("setLoader", true);
      return axios
        .post(`/employee`, payload)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    deleteEmployee({ commit }, payload) {
      store.commit("setLoader", true);
      return axios
        .delete(`/employee/${payload}`)
        .then((response) => {
          const mes = response.data.message;
          commit("removeEmployee", payload);
          Vue.$toast.success(mes);
          return true;
        })
        .catch(() => {
          return false;
        });
    },

    getById(_, id) {
      store.commit("setLoader", true);
      return axios
        .get(`/employee/${id}`)
        .then((response) => {
          const res = response.data.data.employeeDto;
          return res;
        })
        .catch(() => {
          return false;
        });
    },

    updateEmployee(_, payload) {
      store.commit("setLoader", true);
      return axios
        .put(`/employee/${payload.id}`, payload.formData)
        .then((response) => {
          const res = response.data.message;
          Vue.$toast.success(res);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
