<template>
  <div class="career-page">
    <Profession />
    <Institution />
    <Vacancies />
  </div>
</template>
<script>
import Vacancies from "@/components/career/Vacancies.vue";
import Institution from "@/components/career/Institution.vue";
import Profession from "@/components/career/Profession.vue";
export default {
  name: "CareerPage",
  components: {
    Institution,
    Profession,
    Vacancies,
  },
};
</script>