export default class Employee {
  constructor(item, lang) {
    this._setId(item);
    this._setImage(item);
    this._setPosition(item.position, lang);
    this._modifyUser(item, lang);
  }

  /**
   * set id
   * @param {id} param0
   */
  _setId({ id }) {
    this.id = id;
  }

  /**
   * set image
   * @param {photoUrl} param0
   */
  _setImage({ photoUrl }) {
    this.image = photoUrl;
  }

  /**
   * set position
   * @param {employee} item
   * @param {language} lang
   */
  _setPosition(position, lang) {
    let key = "positionName_" + lang;
    this.position = position[key];
  }

  /**
   * modify user fullname
   * @param {fullname} item
   * @param {language} lang
   */
  _modifyUser(item, lang) {
    let key1 = "name_" + lang;
    let key2 = "surname_" + lang;
    this.fullname = item[key1] + " " + item[key2];
  }
}
