<template>
  <div class="get-staff-page">
    <NurseAppeals />
  </div>
</template>
<script>
import NurseAppeals from "../../components/nurses/NurseAppeals.vue";
export default {
  components: {
    NurseAppeals,
  },
};
</script>
