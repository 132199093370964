<template>
  <section class="slider-edit">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.EditSlider" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mb-5">
                <language @lang="langChanged($event)" />
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="slider.isActive"
                  :label="$store.state.statics.Activity"
                  style="width: fit-content"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-row v-show="lang === '_az'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.urL_az"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("az") }} </label>
                    <vue-editor v-model="slider.title_az"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('az')"
                      v-model="slider.description_az"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_de'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.urL_de"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("de") }} </label>
                    <vue-editor v-model="slider.title_de"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('de')"
                      v-model="slider.description_de"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_en'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.urL_en"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("en") }} </label>
                    <vue-editor v-model="slider.title_en"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('en')"
                      v-model="slider.description_en"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_ru'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.urL_ru"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("ru") }} </label>
                    <vue-editor v-model="slider.title_ru"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('ru')"
                      v-model="slider.description_ru"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="lang === '_tr'">
                  <v-col cols="12">
                    <v-text-field
                      :label="$store.state.statics.Link"
                      v-model="slider.urL_tr"
                      dense
                      outlined
                      hide-details
                      background-color="inputs"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>{{ name("tr") }} </label>
                    <vue-editor v-model="slider.title_tr"></vue-editor>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      :label="description('tr')"
                      v-model="slider.description_tr"
                      dense
                      outlined
                      background-color="inputs"
                      counter
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <image-input
                  :image="slider.photoUrl"
                  @new-image="changePhoto($event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="secondary"
                  class="mr-3"
                  @click="$router.push({ name: 'home' })"
                >
                  {{ $store.state.statics.Back }}
                </v-btn>
                <v-btn color="primary" @click="updateSlider">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
import {objectToFormData} from '@/functions/to-form-data';

export default {
  data() {
    return {
      slider: {},
    };
  },
  methods: {
    name(e) {
      return this.$store.state.statics.Title + " (" + e + ")";
    },
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    updateSlider() {
      let l = this.slider;
      this.$store.dispatch("slider/updateSlider", { id: l.id, formData: objectToFormData(l)});
    },
    changePhoto(e) {
      this.slider.photo = e;
    },
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch("slider/getById", id).then((res) => {
      this.slider = res;
    });
  },
  mixins: [lang],
};
</script>