<template>
  <section class="offer">
    <v-row>
      <v-col cols="12">
        <section-title :title="$store.state.statics.Suggest" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <language @lang="langChanged($event)" />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="offerTypes"
                  item-text="name"
                  item-value="id"
                  :label="$store.state.statics.Title"
                  dense
                  v-model="offerType"
                  @change="changeOfferType"
                  outlined
                  background-color="inputs"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" v-show="offerType">
                <v-row>
                  <v-col cols="12" v-show="lang === '_az'">
                    <label>{{ description("az") }} </label>
                    <vue-editor v-model="offer.description_az"></vue-editor>
                  </v-col>
                  <v-col cols="12" v-show="lang === '_de'">
                    <label>{{ description("de") }} </label>
                    <vue-editor v-model="offer.description_de"></vue-editor>
                  </v-col>
                  <v-col cols="12" v-show="lang === '_en'">
                    <label>{{ description("en") }} </label>
                    <vue-editor v-model="offer.description_en"></vue-editor>
                  </v-col>
                  <v-col cols="12" v-show="lang === '_ru'">
                    <label>{{ description("ru") }} </label>
                    <vue-editor v-model="offer.description_ru"></vue-editor>
                  </v-col>
                  <v-col cols="12" v-show="lang === '_tr'">
                    <label>{{ description("tr") }} </label>
                    <vue-editor v-model="offer.description_tr"></vue-editor>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="changeOffer">
                  {{ $store.state.statics.Approve }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </section>
</template>
<script>
import lang from "@/mixins/lang.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      offerType: null,
      offer: {},
    };
  },
  computed: {
    ...mapState({
      offerTypes: (state) => state.offer.offerTypes,
    }),
  },
  methods: {
    description(e) {
      return this.$store.state.statics.Description + " (" + e + ")";
    },
    changeOfferType() {
      this.$store.dispatch("offer/getOfferById", this.offerType).then((res) => {
        this.offer = res;
      });
    },
    changeOffer() {
      this.$store.dispatch("offer/updateOffer", {
        ...this.offer,
        suggestTypeId: this.offer.suggestType.id,
      });
    },
  },
  created() {
    this.$store.dispatch("offer/getOfferTypes");
  },
  mixins: [lang],
};
</script>